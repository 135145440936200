import type { Routine } from '@sb/types';
import { usePausedWidget } from '@sbrc/components/visualizer-view-shared/warning-widget/usePausedWidget';

import GoToRoutineButton from '../../visualizer-view-shared/GoToRoutineButton';
import GoToSceneViewButton from '../../visualizer-view-shared/GoToSceneViewButton';
import { useMoveRobotViewContext } from '../shared';
import StopRoutineButton from '../StopRoutineButton';

import styles from './NonIdleModeRoutineRunningView.module.css';

interface NonIdleModeRoutineRunningViewProps {
  routine: Routine.ConvertedResponse;
}

const NonIdleModeRoutineRunningView = ({
  routine,
}: NonIdleModeRoutineRunningViewProps) => {
  const { robot, isControllingLiveRobot } = useMoveRobotViewContext();

  const pausedWidget = usePausedWidget({
    isVizbot: !isControllingLiveRobot,
    robot,
  });

  if (pausedWidget) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{pausedWidget}</>;
  }

  return (
    <div className={styles.nonIdleModeRoutineRunningView}>
      <GoToRoutineButton
        robotID={robot.id}
        routineID={String(routine.id)}
        routineName={String(routine.name)}
      />
      <GoToSceneViewButton robotID={robot.id} />
      <StopRoutineButton />
    </div>
  );
};

export default NonIdleModeRoutineRunningView;
