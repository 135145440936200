import { useEffect, useRef, useState } from 'react';

import type { CartesianPose } from '@sb/geometry';
import type { DeviceCommand, DynamicBaseState } from '@sb/integrations/device';
import { calculateEndEffectorStateFromCommand } from '@sb/integrations/gripper-general';
import type { ArmJointPositions, FrameOfReference } from '@sb/motion-planning';
import type { ArmPosition } from '@sb/routine-runner';
import type { Robot, Routine } from '@sb/types';
import type { AxesMode } from '@sb/visualizer';
import AppHeader from '@sbrc/components/header/AppHeader';
import {
  useIsRobotBraked,
  useIsRobotConnected,
  useLiveRoutineRunnerHandle,
  useVizbotRoutineRunnerHandle,
} from '@sbrc/hooks';
import { convertEulerPose, UserSession } from '@sbrc/utils';

import RobotVisualizer from '../robot-visualizer/RobotVisualizer';

import NonIdleMoveView from './control-panel-nonidle-view/NonIdleModeView';
import { ControlPanelWidgetView } from './control-panel-widgets/ControlPanelWidgetView';
import ControlModeButtonGroup from './ControlModeButtonGroup';
import MoveRobotControlToggle from './MoveRobotControlToggle';
import MoveRobotHeaderNav from './MoveRobotHeaderNav';
import MoveRobotLiveStream from './MoveRobotLiveStream';
import MoveRobotViewPosePopover from './MoveRobotViewPosePopover';
import type {
  ControlModeState,
  NonIdleState,
  SyncingRobot,
  TargetJointAnglesState,
  TargetPoseState,
} from './shared';
import {
  MoveRobotViewContext,
  ROBOT_CONTROL_MODE_DEFAULT,
  useGhostRobotState,
} from './shared';
import SyncPositionsButton from './SyncPositionsButton';

import styles from './MoveRobotView.module.css';

interface InitialState {
  jointAngles?: ArmJointPositions;
  pose?: CartesianPose;
  endEffectorDefaultCommand?: DeviceCommand;
  dynamicBaseState?: DynamicBaseState;
  payloadKg?: number;
}

interface MoveRobotViewProps {
  controlMode?: ControlModeState;
  /**
   * An initial state to load the visualizer with (i.e. when navigating from the move
   * view modal in the step configuration wizard).
   */
  initialState?: InitialState;
  isOnConfirmDisabled?: boolean;
  onConfirmArmPosition?: (armPosition: ArmPosition) => void;
  onConfirmEndEffector?: (
    endEffectorCommand: DeviceCommand,
    payload?: number,
  ) => void;
  onPositionSync?: () => void;
  onReturn: () => void;
  robot: Robot.ConvertedResponse;
  routine?: Routine.ConvertedResponse | null;
}

const MoveRobotView = ({
  controlMode: currentControlMode,
  initialState,
  isOnConfirmDisabled,
  onConfirmArmPosition,
  onConfirmEndEffector,
  onPositionSync,
  onReturn,
  robot: selectedRobot,
  routine,
}: MoveRobotViewProps) => {
  const robotID = selectedRobot.id;

  const liveRoutineRunnerHandle = useLiveRoutineRunnerHandle({ robotID });

  const vizbotRoutineRunnerHandle = useVizbotRoutineRunnerHandle({
    routine,
  });

  const [frameOfReference, setFrameOfReference] =
    useState<FrameOfReference>('robotArm');

  // if onPositionSync is set then user may only control the live robot (to sync it with the target position)
  const forceIsControllingLiveRobot = Boolean(onPositionSync);

  const [isControllingLiveRobot, setIsControllingLiveRobot] =
    useState<boolean>(true);

  const [isCameraView, setIsCameraView] = useState<boolean>(false);

  const [syncingRobot, setSyncingRobot] = useState<SyncingRobot>(null);

  const [controlMode, setControlMode] = useState<ControlModeState>(
    currentControlMode ?? ROBOT_CONTROL_MODE_DEFAULT,
  );

  const [nonIdleMode, setNonIdleMode] = useState<NonIdleState>(null);

  const [targetJointAngles, setTargetJointAngles] =
    useState<TargetJointAnglesState>({
      liveRobot: initialState?.jointAngles ?? null,
      vizbot: null,
    });

  const [targetPose, setTargetPose] = useState<TargetPoseState>(() => {
    const pose = initialState?.pose
      ? convertEulerPose.fromCartesian(initialState.pose)
      : null;

    return { liveRobot: pose, vizbot: pose };
  });

  /**  assign the respective routine runner handle based on controlling view input */
  const controlViewRoutineRunnerHandle = isControllingLiveRobot
    ? liveRoutineRunnerHandle
    : vizbotRoutineRunnerHandle;

  const isLiveRobotConnected = useIsRobotConnected({ robotID });

  const isRealRobotBraked = useIsRobotBraked({ robotID });

  const [gripperCommand, setGripperCommand] = useState<DeviceCommand | null>(
    null,
  );

  // If user provides an initial state, sync up the visualizer state on mount
  const initialStateToRender = useRef<InitialState | undefined>(initialState);

  useEffect(() => {
    if (!initialStateToRender.current) {
      return;
    }

    const {
      jointAngles,
      endEffectorDefaultCommand,
      payloadKg,
      dynamicBaseState,
    } = initialStateToRender.current;

    if (jointAngles) {
      vizbotRoutineRunnerHandle.setJointPositions(jointAngles);
    }

    if (endEffectorDefaultCommand) {
      vizbotRoutineRunnerHandle.setGripperState(
        calculateEndEffectorStateFromCommand(endEffectorDefaultCommand),
      );
    }

    if (payloadKg) {
      vizbotRoutineRunnerHandle.setRobotPayload({ mass: payloadKg });
    }

    if (dynamicBaseState) {
      vizbotRoutineRunnerHandle.setDynamicBaseState(dynamicBaseState);
    }

    // clear initialStateToRender so it is only applied once
    initialStateToRender.current = undefined;
  }, [vizbotRoutineRunnerHandle]);

  const axesMode = ((): AxesMode => {
    if (nonIdleMode !== null) {
      return null;
    }

    if (controlMode === 'toolControl') {
      return frameOfReference;
    }

    if (controlMode === 'toolControlTarget') {
      return 'robotArm';
    }

    if (
      controlMode === 'jointControlSingle' ||
      controlMode === 'jointControlDual'
    ) {
      return 'joints';
    }

    return null;
  })();

  const ghostRobotState = useGhostRobotState(
    controlMode,
    isControllingLiveRobot,
    targetJointAngles,
    targetPose,
  );

  useEffect(() => {
    if (!isControllingLiveRobot) {
      setIsCameraView(false);
    }
  }, [isControllingLiveRobot, vizbotRoutineRunnerHandle]);

  // Update the user session when controlling live robot changes.
  useEffect(() => {
    const shouldDisplayUserBadge =
      (isControllingLiveRobot || controlMode === 'syncPositions') &&
      isLiveRobotConnected;

    UserSession.controlRobot(shouldDisplayUserBadge ? robotID : null);

    /**
     * When a user navigates away from the page, they could be controlling
     * the live robot. This makes sure we're telling our user session service
     * that the user is no longer controlling the live robot when this component
     * unmounts.
     */
    return () => {
      UserSession.controlRobot(null);
    };
  }, [controlMode, isControllingLiveRobot, isLiveRobotConnected, robotID]);

  return (
    <MoveRobotViewContext.Provider
      value={{
        controlMode,
        controlViewRoutineRunnerHandle,
        frameOfReference,
        gripperCommand,
        endEffectorDefaultCommand: initialState?.endEffectorDefaultCommand,
        setGripperCommand,
        isCameraView,
        isControllingLiveRobot,
        isLiveRobotConnected,
        isModal: Boolean(currentControlMode) || Boolean(initialState),
        isRealRobotBraked,
        nonIdleMode,
        onSyncPositionComplete: onPositionSync,
        robot: selectedRobot,
        routine,
        setControlMode,
        setFrameOfReference,
        setIsCameraView,
        setIsControllingLiveRobot,
        setNonIdleMode,
        setSyncingRobot,
        setTargetJointAngles,
        setTargetPose,
        syncingRobot,
        targetJointAngles,
        targetPose,
      }}
    >
      <div className={styles.moveRobotView}>
        <AppHeader robot={selectedRobot}>
          <MoveRobotHeaderNav
            onReturn={onReturn}
            onConfirmArmPosition={onConfirmArmPosition}
            onConfirmEndEffector={onConfirmEndEffector}
            isOnConfirmDisabled={isOnConfirmDisabled}
          />
        </AppHeader>

        {!isCameraView && (
          <RobotVisualizer
            robotID={robotID}
            isVizbot={!isControllingLiveRobot}
            axesMode={axesMode}
            showOtherRobotGhost={controlMode === 'syncPositions'}
            ghostRobotState={ghostRobotState ?? undefined}
            isFullPage
          />
        )}

        <MoveRobotLiveStream />
        {!forceIsControllingLiveRobot && <MoveRobotControlToggle />}
        <SyncPositionsButton />
        <MoveRobotViewPosePopover />
        <NonIdleMoveView />
        <ControlPanelWidgetView isHidden={nonIdleMode !== null} />
        <ControlModeButtonGroup />
      </div>
    </MoveRobotViewContext.Provider>
  );
};

export default MoveRobotView;
