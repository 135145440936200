import { useCallback } from 'react';

import { Button, Typography } from '@sb/ui/components';
import { StopIcon } from '@sb/ui/icons';
import { useStopRobot } from '@sbrc/hooks';

import { useMoveRobotViewContext } from './shared';

const StopRoutineButton = () => {
  const { robot } = useMoveRobotViewContext();

  const robotID = robot.id;

  const { isStopping, stopRobot } = useStopRobot({ robotID });

  const onStopRobotClick = useCallback(() => {
    stopRobot();
  }, [stopRobot]);

  return (
    <Button
      isFullWidth
      onClick={onStopRobotClick}
      disabled={isStopping}
      endIcon={<StopIcon />}
      variant="blackPrimary"
    >
      <Typography variant="medium">Stop Robot</Typography>
    </Button>
  );
};

export default StopRoutineButton;
