import { useEffect, useState } from 'react';

import { areGripperStatesEqual } from '@sb/integrations/gripper-general';
import {
  useLiveRoutineRunnerHandle,
  useVizbotRoutineRunnerHandle,
} from '@sbrc/hooks';
import { areJointAnglesEqual } from '@sbrc/utils';

import { useMoveRobotViewContext } from './shared';

export default function useIsRobotSynced() {
  const { robot } = useMoveRobotViewContext();

  const realRoutineRunnerHandle = useLiveRoutineRunnerHandle({
    robotID: robot.id,
  });

  const vizbotRoutineRunnerHandle = useVizbotRoutineRunnerHandle();

  const [isArmSynced, setIsArmSynced] = useState(true);
  const [isGripperSynced, setIsGripperSynced] = useState(true);

  useEffect(() => {
    const unsubscribeActions = [
      realRoutineRunnerHandle.onStateChange(
        (state) =>
          areJointAnglesEqual(
            state.kinematicState.jointAngles,
            vizbotRoutineRunnerHandle.getState()?.kinematicState.jointAngles,
          ),
        Object.is,
        setIsArmSynced,
      ),

      realRoutineRunnerHandle.onStateChange(
        (state) =>
          areGripperStatesEqual(
            state.kinematicState.gripperState,
            vizbotRoutineRunnerHandle.getState()?.kinematicState.gripperState,
          ),
        Object.is,
        setIsGripperSynced,
      ),

      vizbotRoutineRunnerHandle.onStateChange(
        (state) =>
          areJointAnglesEqual(
            state.kinematicState.jointAngles,
            realRoutineRunnerHandle.getState()?.kinematicState.jointAngles,
          ),
        Object.is,
        setIsArmSynced,
      ),

      vizbotRoutineRunnerHandle.onStateChange(
        (state) =>
          areGripperStatesEqual(
            state.kinematicState.gripperState,
            realRoutineRunnerHandle.getState()?.kinematicState.gripperState,
          ),
        Object.is,
        setIsGripperSynced,
      ),
    ];

    return () => {
      unsubscribeActions.forEach((unsubscribe) => unsubscribe());
    };
  }, [vizbotRoutineRunnerHandle, realRoutineRunnerHandle]);

  return {
    isArmSynced,
    isGripperSynced,
    isPositionsSynced: isArmSynced && isGripperSynced,
  };
}
