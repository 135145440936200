import {
  Modal,
  ModalContent,
  ModalHeader,
  ToggleButton,
  ToggleButtonGroup,
} from '@sb/ui/components';

import type { RotationState, RotationUnit } from './types';

import styles from './ToolCoordinateRotationTypeModal.module.css';

interface ToolCoordinateRotationTypeModalProps {
  isOpen: boolean;
  onClose: () => void;
  onRotationChange: (rotation: RotationState) => void;
  onUnitChange: (unit: RotationUnit) => void;
  rotation: RotationState;
  rotationUnit: RotationUnit;
}

const ToolCoordinateRotationTypeModal = ({
  isOpen,
  onClose,
  onRotationChange,
  onUnitChange,
  rotation,
  rotationUnit,
}: ToolCoordinateRotationTypeModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader title="Tool Pose Settings" />

      <ModalContent className={styles.modalContent}>
        <div className={styles.toggleButtonGroup}>
          <ToggleButtonGroup label="Rotation Type">
            <ToggleButton
              isSelected={rotation === 'euler'}
              onClick={() => onRotationChange('euler')}
            >
              Euler Angles
            </ToggleButton>
            <ToggleButton
              isSelected={rotation === 'quaternion'}
              onClick={() => onRotationChange('quaternion')}
            >
              Quaternion
            </ToggleButton>
          </ToggleButtonGroup>
        </div>

        <div className={styles.toggleButtonGroup}>
          <ToggleButtonGroup label="Rotation Unit">
            <ToggleButton
              isSelected={rotationUnit === 'degrees'}
              onClick={() => onUnitChange('degrees')}
              disabled={rotation === 'quaternion'}
            >
              Degrees
            </ToggleButton>
            <ToggleButton
              isSelected={rotationUnit === 'radians'}
              onClick={() => onUnitChange('radians')}
              disabled={rotation === 'quaternion'}
            >
              Radians
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default ToolCoordinateRotationTypeModal;
