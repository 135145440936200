import { EditGridPositionList } from './EditGridPositionList';
import { EditPositionList } from './EditPositionList';
import { EditSinglePosition } from './EditSinglePosition';
import { EditEnvironmentObject } from './environment-object/EditEnvironmentObject';
import type { EditSpaceItemProps } from './types';

export function EditSpaceItem({ spaceItem, ...rest }: EditSpaceItemProps) {
  const { kind } = spaceItem;

  switch (kind) {
    case 'freeformPositionList':
      return <EditPositionList spaceItem={spaceItem} {...rest} />;
    case 'gridPositionList':
      return <EditGridPositionList spaceItem={spaceItem} {...rest} />;
    case 'singlePosition':
      return <EditSinglePosition spaceItem={spaceItem} {...rest} />;
    case 'environmentObject':
      return <EditEnvironmentObject spaceItem={spaceItem} {...rest} />;
    default: {
      const exhaustive: never = kind; // ensure type exhaustive
      throw new Error(`Unexpected kind for equipment ${exhaustive}`);
    }
  }
}
