import RobotControlToggle from '../visualizer-view-shared/RobotControlToggle';

import { useMoveRobotViewContext } from './shared';

export default function MoveRobotControlToggle() {
  const {
    isCameraView,
    isControllingLiveRobot,
    robot,
    setIsControllingLiveRobot,
  } = useMoveRobotViewContext();

  return (
    <RobotControlToggle
      isCameraView={isCameraView}
      isControllingLiveRobot={isControllingLiveRobot}
      isSwitchableOnRunningRoutine
      onIsControllingLiveRobotChange={setIsControllingLiveRobot}
      robot={robot}
    />
  );
}
