import cx from 'classnames';
import { useState } from 'react';

import type { Routine } from '@sb/types';
import { Button, Dropdown, MenuItem, Typography } from '@sb/ui/components';
import {
  AddAfterIcon,
  AddBeforeIcon,
  ArrowDownFilledIcon,
  ArrowUpFilledIcon,
  MoveArrowIcon,
  SaveIcon,
  SettingsIcon,
  TrashIcon,
} from '@sb/ui/icons';
import { display } from '@sb/ui/styles';
import type { UseRoutineRunnerHandleArguments } from '@sbrc/hooks';
import { useIsRobotAtJointAngles } from '@sbrc/hooks';

import { useGetSyncButtonProps } from './useGetSyncButtonProps';

import styles from './EditPositionItem.module.css';

export interface EditPositionItemProps {
  id: number;
  position: Routine.Position;
  isSelected: boolean;
  isUnsaved: boolean;
  isRobotAtAnyPositionInList: boolean;
  onSelect: () => void;
  onAddBefore: () => void;
  onAddAfter: () => void;
  onSave: () => void;
  isRemoveDisabled: boolean;
  onRemove: () => void;
  isMoveUpDisabled: boolean;
  onMoveUp: () => void;
  isMoveDownDisabled: boolean;
  onMoveDown: () => void;
  routineRunnerHandleArgs: UseRoutineRunnerHandleArguments;
}

export function EditPositionItem({
  id,
  position,
  isSelected,
  isUnsaved,
  isRobotAtAnyPositionInList,
  onSelect,
  onAddBefore,
  onAddAfter,
  onSave,
  isRemoveDisabled,
  onRemove,
  isMoveUpDisabled,
  onMoveUp,
  isMoveDownDisabled,
  onMoveDown,
  routineRunnerHandleArgs,
}: EditPositionItemProps) {
  const getSyncButtonProps = useGetSyncButtonProps(routineRunnerHandleArgs);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const isRobotAtPosition = useIsRobotAtJointAngles(
    routineRunnerHandleArgs,
    position.jointAngles,
  );

  return (
    <div
      className={cx(styles.item, isSelected && styles.isSelected)}
      data-testid={`position-list-item-${id}`}
      onMouseDown={onSelect}
      onTouchStart={onSelect}
      role="button"
    >
      <Typography className={styles.id}>{id}</Typography>
      <Button
        variant="secondary"
        startIcon={<SaveIcon />}
        onClick={onSave}
        disabled={isRobotAtAnyPositionInList}
      >
        {isUnsaved ? 'Set' : 'Replace'}
      </Button>

      {isUnsaved ? (
        <div />
      ) : (
        <Button
          variant="gray"
          startIcon={<MoveArrowIcon />}
          {...getSyncButtonProps(position.jointAngles, isRobotAtPosition)}
        >
          Go Here
        </Button>
      )}

      <Dropdown
        isOpen={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        content={
          <>
            <MenuItem
              hasDivider
              onClick={onRemove}
              startIcon={<TrashIcon />}
              variant="alertPrimary"
              disabled={isRemoveDisabled}
            >
              Delete Position
            </MenuItem>
            <MenuItem
              hasDivider
              onClick={onAddBefore}
              startIcon={<AddBeforeIcon />}
            >
              Add Position Before
            </MenuItem>
            <MenuItem
              hasDivider
              onClick={onAddAfter}
              startIcon={<AddAfterIcon />}
            >
              Add Position After
            </MenuItem>
            <MenuItem
              hasDivider
              onClick={onMoveUp}
              startIcon={<ArrowUpFilledIcon />}
              disabled={isMoveUpDisabled}
            >
              Reorder Up
            </MenuItem>
            <MenuItem
              hasDivider
              onClick={onMoveDown}
              startIcon={<ArrowDownFilledIcon />}
              disabled={isMoveDownDisabled}
            >
              Reorder Down
            </MenuItem>
          </>
        }
      >
        <SettingsIcon
          aria-label="position-item-menu"
          onClick={() => setIsMenuOpen(true)}
          role="button"
          size="small"
          className={display.flex.row}
        />
      </Dropdown>
    </div>
  );
}
