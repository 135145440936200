import { useCallback } from 'react';

import type { ArmJointPositions } from '@sb/motion-planning';

import SpaceWidget from '../../visualizer-view-shared/space-widget/SpaceWidget';
import { useMoveRobotViewContext } from '../shared';

export default function MoveRobotSpaceWidget() {
  const {
    controlMode,
    isControllingLiveRobot,
    robot,
    routine,
    setControlMode,
    setTargetJointAngles: setTargetJointAnglesCtx,
  } = useMoveRobotViewContext();

  const setTargetJointAngles = useCallback(
    (jointAngles: ArmJointPositions | null) =>
      setTargetJointAnglesCtx((prevAngles) => {
        const targetKey = isControllingLiveRobot ? 'liveRobot' : 'vizbot';

        return {
          ...prevAngles,
          [targetKey]: jointAngles,
        };
      }),
    [setTargetJointAnglesCtx, isControllingLiveRobot],
  );

  return (
    <SpaceWidget
      isActive={controlMode === 'space'}
      routine={routine ?? null}
      onClose={() => setControlMode(null)}
      routineRunnerHandleArgs={{
        robotID: robot.id,
        isVizbot: !isControllingLiveRobot,
      }}
      setTargetJointAngles={setTargetJointAngles}
    />
  );
}
