import { convertEulerPose } from '@sbrc/utils';
import type { EulerPose } from '@sbrc/utils';

import { getCurrentCartesianPose, useMoveRobotViewContext } from '../../shared';

export default function useTargetEulerPose() {
  const {
    isControllingLiveRobot,
    controlViewRoutineRunnerHandle,
    setTargetPose,
    targetPose,
  } = useMoveRobotViewContext();

  const targetKey = isControllingLiveRobot ? 'liveRobot' : 'vizbot';

  const setTargetEulerPose = (poseKey: keyof EulerPose, position: number) => {
    setTargetPose((previousState) => {
      let previousTarget = previousState[targetKey];

      if (!previousTarget) {
        const cartesianPose = getCurrentCartesianPose(
          controlViewRoutineRunnerHandle,
        );

        if (cartesianPose) {
          previousTarget = convertEulerPose.fromCartesian(cartesianPose);
        }
      }

      if (previousTarget) {
        return {
          ...previousState,
          [targetKey]: { ...previousTarget, [poseKey]: position },
        };
      }

      return previousState;
    });
  };

  const setAllTargetEulerPose = (newTarget: EulerPose) => {
    setTargetPose((previousState) => {
      return {
        ...previousState,
        [targetKey]: newTarget,
      };
    });
  };

  const resetAllTargets = () => {
    setTargetPose((previousState) => {
      return {
        ...previousState,
        [targetKey]: null,
      };
    });
  };

  return {
    setTargetEulerPose,
    setAllTargetEulerPose,
    resetAllTargets,
    targetPose: targetPose[targetKey],
  };
}
