import { useState } from 'react';

import type { Routine } from '@sb/types';
import { Button, InputField } from '@sb/ui/components';
import { ArrowBackIcon } from '@sb/ui/icons';
import { margin } from '@sb/ui/styles';
import { isDuplicateName } from '@sbrc/utils';

import WidgetBody from '../widget-panel/WidgetBody';
import WidgetView from '../widget-panel/WidgetView';

import { generateNewSpaceItem } from './generateNewSpaceItem';
import { getSpaceItemKindName } from './getSpaceItemKindName';
import SpaceItemIcon from './SpaceItemIcon';
import type { WidgetState } from './types';

interface RenameSpaceItemProps {
  onClose: () => void;
  widgetState: WidgetState;
  setWidgetState: (state: WidgetState) => void;
  spaceList: Routine.SpaceItem[];
}

export default function RenameSpaceItem({
  onClose,
  widgetState,
  setWidgetState,
  spaceList,
}: RenameSpaceItemProps) {
  const [name, setName] = useState(widgetState.renameItem?.name ?? '');

  const [description, setDescription] = useState(
    widgetState.renameItem?.description ?? '',
  );

  const handleSubmit: React.FormEventHandler = (event) => {
    event.preventDefault();

    if (widgetState.renameItem) {
      const item = {
        ...widgetState.renameItem,
        name,
        description,
      };

      setWidgetState({
        ...widgetState,
        updateItem: item,
        renameItem: undefined,
      });
    } else if (widgetState.createItemKind) {
      setWidgetState({
        ...widgetState,
        item: generateNewSpaceItem(
          widgetState.createItemKind,
          name,
          description,
        ),
        createItemKind: undefined,
      });
    }
  };

  const itemKind = widgetState.createItemKind ?? widgetState.renameItem?.kind;

  const itemKindName = itemKind ? getSpaceItemKindName(itemKind) : 'Item';

  const actionTitle = widgetState.createItemKind ? 'New' : 'Rename';

  const isDuplicateSpaceName = spaceList.some(
    (spaceItem) =>
      spaceItem !== widgetState.renameItem &&
      isDuplicateName(spaceItem.name, name),
  );

  const isSaveDisabled =
    (name === '' &&
      (widgetState.createItemKind !== undefined ||
        Boolean(widgetState.renameItem))) ||
    isDuplicateSpaceName;

  return (
    <WidgetView
      onClose={onClose}
      headerTitle={`${actionTitle} ${itemKindName}`}
      headerIcon={
        <>
          <Button
            aria-label="Back"
            data-testid="space-widget-header-back-button"
            variant="gray"
            startIcon={<ArrowBackIcon />}
            size="extraSmall"
            onClick={() => setWidgetState({})}
          />
          {itemKind && <SpaceItemIcon itemKind={itemKind} />}
        </>
      }
    >
      <WidgetBody>
        <form onSubmit={handleSubmit}>
          <InputField
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            autoFocus
            hasError={isDuplicateSpaceName}
            helperText={isDuplicateSpaceName ? 'Name already exists.' : ''}
          >
            Name
          </InputField>

          <InputField
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className={margin.top.small}
          >
            Description
          </InputField>

          <Button
            type="submit"
            disabled={isSaveDisabled}
            className={margin.top.medium}
          >
            {widgetState.renameItem ? 'Save' : 'Create'}
          </Button>
        </form>
      </WidgetBody>
    </WidgetView>
  );
}
