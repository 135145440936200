import { useCallback, useState } from 'react';

import {
  Alert,
  AlertActions,
  AlertDescription,
  AlertTitle,
  Button,
  Checkbox,
} from '@sb/ui/components';
import { useRobotStateKind, useStopRobot } from '@sbrc/hooks';

import styles from './RobotControlToggleAlert.module.css';

interface RobotControlToggleAlertProps {
  isControllingLiveRobot: boolean;
  isOpen: boolean;
  robotID: string;
  onClose: () => void;
  onCloseAndSwitch: () => void;
}

const RobotControlToggleAlert = ({
  isControllingLiveRobot,
  isOpen,
  robotID,
  onClose,
  onCloseAndSwitch,
}: RobotControlToggleAlertProps) => {
  const [isStopRoutineConfirmationChecked, setStopRoutineConfirmationChecked] =
    useState<boolean>(false);

  const routineRunnerArgs = {
    robotID,
    isVizbot: !isControllingLiveRobot,
  };

  const { isStopping, stopRobot } = useStopRobot(routineRunnerArgs);

  const routineRunnerStateKind = useRobotStateKind(routineRunnerArgs);

  const alertControlViewMessage = isControllingLiveRobot
    ? 'Live Robot'
    : 'Visualizer';

  const alertToggleToViewMessage = isControllingLiveRobot
    ? 'Visualizer'
    : 'Live Robot';

  const uncheckStopRobotConfirmation = useCallback(() => {
    setStopRoutineConfirmationChecked(false);
  }, []);

  const onStopRoutineClick = useCallback(() => {
    stopRobot({ onFinally: uncheckStopRobotConfirmation });
  }, [stopRobot, uncheckStopRobotConfirmation]);

  return (
    <Alert isOpen={isOpen}>
      {routineRunnerStateKind === 'RoutineRunning' && (
        <>
          <AlertTitle className={styles.title}>
            Control Switching is Disabled
          </AlertTitle>

          <AlertDescription className={styles.description}>
            The <strong>{alertControlViewMessage}</strong> is currently playing
            the routine. To switch to{' '}
            <strong>Controlling {alertToggleToViewMessage}</strong>, please stop
            the routine.
          </AlertDescription>

          <Checkbox
            checked={isStopRoutineConfirmationChecked}
            className={styles.checkbox}
            onChange={(e) => {
              setStopRoutineConfirmationChecked(e.target.checked);
            }}
          >
            I confirm that it is safe to stop the routine.
          </Checkbox>

          <AlertActions>
            <Button
              disabled={!isStopRoutineConfirmationChecked || isStopping}
              onClick={onStopRoutineClick}
              variant="primary"
            >
              Stop Routine
            </Button>

            <Button onClick={onClose} variant="gray">
              Cancel
            </Button>
          </AlertActions>
        </>
      )}

      {routineRunnerStateKind === 'Idle' && (
        <>
          <AlertTitle className={styles.title}>
            Control Switching is Enabled
          </AlertTitle>

          <AlertDescription className={styles.description}>
            The <strong>{alertControlViewMessage}</strong> has successfully
            stopped, please click <span>Close</span> to switch to{' '}
            <strong>Controlling {alertToggleToViewMessage}</strong>.
          </AlertDescription>

          <AlertActions>
            <Button onClick={onCloseAndSwitch} variant="gray">
              Close
            </Button>
          </AlertActions>
        </>
      )}
    </Alert>
  );
};

export default RobotControlToggleAlert;
