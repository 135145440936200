import type { Routine } from '@sb/types';
import { Button, FileInput, InputLabel } from '@sb/ui/components';
import { ArrowBackIcon } from '@sb/ui/icons';
import { display } from '@sb/ui/styles';
import { useToast } from '@sbrc/hooks';
import { uploadFileToStorage } from '@sbrc/services';

import WidgetBody from '../../../widget-panel/WidgetBody';
import WidgetView from '../../../widget-panel/WidgetView';
import SpaceItemIcon from '../../SpaceItemIcon';
import type { EditSpaceItemProps } from '../types';

export function EditEnvironmentObject({
  onClose,
  spaceItem,
  widgetState,
  setWidgetState,
}: EditSpaceItemProps<Routine.EnvironmentObject>) {
  const { setToast } = useToast();

  const save = (changes: Partial<Routine.EnvironmentObject>) => {
    const item = { ...spaceItem, ...changes };

    setWidgetState({
      ...widgetState,
      item,
      updateItem: item,
    });
  };

  return (
    <WidgetView
      onClose={onClose}
      headerTitle={spaceItem.name}
      headerIcon={
        <>
          <Button
            aria-label="Back"
            data-testid="space-widget-header-back-button"
            variant="gray"
            startIcon={<ArrowBackIcon />}
            size="extraSmall"
            onClick={() => setWidgetState({})}
          />
          <SpaceItemIcon itemKind={spaceItem.kind} size="small" />
        </>
      }
    >
      <WidgetBody>
        <form
          className={display.flex.column}
          onSubmit={(e) => e.preventDefault()}
        >
          <InputLabel>File</InputLabel>
          <FileInput
            accept=".glb,.gltf,model/*"
            downloadURL={spaceItem.fileURL ?? undefined}
            fileName={spaceItem.fileName ?? undefined}
            uploadFile={async (file) => {
              try {
                const response = await uploadFileToStorage({ file });
                save({ fileURL: response.downloadURL, fileName: file.name });
              } catch (e) {
                setToast({
                  kind: 'error',
                  message: `File upload failed ${e?.message}`,
                });
              }
            }}
          />
        </form>
      </WidgetBody>
    </WidgetView>
  );
}
