import { UnbrakeButton } from '@sbrc/components/visualizer-view-shared/unbrake-button/UnbrakeButton';
import WarningWidget from '@sbrc/components/visualizer-view-shared/warning-widget/WarningWidget';
import WidgetText from '@sbrc/components/visualizer-view-shared/widget-panel/WidgetText';

import { useMoveRobotViewContext } from '../shared';

const NonIdleModeUnbrakeView = () => {
  const { isControllingLiveRobot, robot } = useMoveRobotViewContext();

  const testID = isControllingLiveRobot
    ? 'unbrake-button-on-liverobot-braked-modal'
    : 'unbrake-button-on-robot-braked-modal';

  return (
    <WarningWidget>
      <WidgetText>
        <strong>Robot is Braked.</strong>
        <br />
        Please review the settings below and click Unbrake to start interacting
        with {isControllingLiveRobot ? 'Live Robot' : 'Visualizer'}.
      </WidgetText>

      <UnbrakeButton
        isVizbot={!isControllingLiveRobot}
        robotID={robot.id}
        testID={testID}
      />
    </WarningWidget>
  );
};

export default NonIdleModeUnbrakeView;
