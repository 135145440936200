import cx from 'classnames';
import { useCallback } from 'react';

import { Typography } from '@sb/ui/components';
import { RobotIcon, VideoIcon } from '@sb/ui/icons';
import { useVizbotRoutineRunnerHandle } from '@sbrc/hooks';

import { useMoveRobotViewContext } from './shared';

import styles from './MoveRobotCamera.module.css';

const MoveRobotCamera = () => {
  const {
    controlMode,
    isCameraView,
    isControllingLiveRobot,
    isLiveRobotConnected,
    setIsCameraView,
  } = useMoveRobotViewContext();

  const vizbotRoutineRunnerHandle = useVizbotRoutineRunnerHandle();

  const isControllingLiveRobotDisconnected =
    isControllingLiveRobot && !isLiveRobotConnected;

  const onCameraMount = useCallback(() => {
    if (isCameraView) {
      setIsCameraView(false);
      // remount visualized robot with kinematic state
      const vizbotState = vizbotRoutineRunnerHandle.getState();
      const jointPositions = vizbotState?.kinematicState.jointAngles;
      vizbotRoutineRunnerHandle.setJointPositions(jointPositions!);
    }

    if (!isCameraView) {
      setIsCameraView(true);
    }
  }, [isCameraView, setIsCameraView, vizbotRoutineRunnerHandle]);

  return (
    <div className={styles.moveRobotCamera}>
      {controlMode === null && (
        <div className={styles.labelGroup}>
          <Typography className={styles.label}>Robot</Typography>
          <Typography className={styles.label}>Camera</Typography>
        </div>
      )}

      <div className={styles.buttonGroup}>
        <button
          className={cx(styles.button, styles.robotButton, {
            [styles.buttonSelected]: !isCameraView,
          })}
          onClick={onCameraMount}
        >
          <RobotIcon className={styles.icon} />
        </button>

        <button
          className={cx(styles.button, {
            [styles.buttonSelected]: isCameraView,
          })}
          disabled={isControllingLiveRobotDisconnected}
          onClick={onCameraMount}
        >
          <VideoIcon className={styles.icon} />
        </button>
      </div>
    </div>
  );
};

export default MoveRobotCamera;
