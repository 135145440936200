import cx from 'classnames';
import { useCallback, useEffect, useState } from 'react';

import type { Robot } from '@sb/types';
import { RobotIcon, VisualizerIcon } from '@sb/ui/icons';
import { useRobotStateKind } from '@sbrc/hooks';

import RobotControlToggleAlert from './RobotControlToggleAlert';

import styles from './RobotControlToggle.module.css';

interface RobotControlToggleProps {
  isCameraView?: boolean;
  isControllingLiveRobot: boolean;
  isLiveRobotConnected?: boolean;
  isSwitchableOnRunningRoutine?: boolean;
  onIsControllingLiveRobotChange: (isControllingLiveRobot: boolean) => void;
  robot: Robot.ConvertedResponse;
}

const RobotControlToggle = ({
  isCameraView,
  isControllingLiveRobot,
  isLiveRobotConnected,
  isSwitchableOnRunningRoutine,
  onIsControllingLiveRobotChange,
  robot,
}: RobotControlToggleProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const realRoutineRunnerKind = useRobotStateKind({
    robotID: robot.id,
  });

  const isRealRoutineRunning = realRoutineRunnerKind === 'RoutineRunning';

  const vizbotRoutineRunnerKind = useRobotStateKind({
    robotID: robot.id,
    isVizbot: true,
  });

  const isVizbotRoutineRunning = vizbotRoutineRunnerKind === 'RoutineRunning';

  const controlViewRoutineRunnerKind = useRobotStateKind({
    robotID: robot.id,
    isVizbot: !isControllingLiveRobot,
  });

  const isControlViewStateFailure = controlViewRoutineRunnerKind === 'Failure';

  const openModal = () => {
    setIsModalOpen(true);
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  const onCloseModalAndSwitchRobotControl = useCallback(() => {
    if (!isVizbotRoutineRunning && !isRealRoutineRunning) {
      setIsModalOpen(false);
    }

    onIsControllingLiveRobotChange(!isControllingLiveRobot);
  }, [
    isControllingLiveRobot,
    isRealRoutineRunning,
    isVizbotRoutineRunning,
    onIsControllingLiveRobotChange,
  ]);

  const handleLiveRobotSwitch = () => {
    if (isVizbotRoutineRunning && !isSwitchableOnRunningRoutine) {
      openModal();
    }

    if (!isVizbotRoutineRunning || isSwitchableOnRunningRoutine) {
      onIsControllingLiveRobotChange(true);
    }
  };

  const handleSimulatedRobotSwitch = () => {
    if (isRealRoutineRunning && !isSwitchableOnRunningRoutine) {
      openModal();
    }

    if (!isRealRoutineRunning || isSwitchableOnRunningRoutine) {
      onIsControllingLiveRobotChange(false);
    }
  };

  // If the robot goes into failure or loses connection while the modal is open, we
  // should cancel the modal. In both these cases, the robot's state will return to
  // 'Idle' and the user can switch between robot control.
  useEffect(() => {
    if (isControlViewStateFailure || !isLiveRobotConnected) onCloseModal();
  }, [isControlViewStateFailure, isLiveRobotConnected]);

  return (
    <div className={styles.robotControlToggle}>
      <div className={styles.toggle} />

      <div className={styles.buttonGroup}>
        <button
          className={cx(
            styles.button,
            isControllingLiveRobot && styles.buttonSelected,
          )}
          onClick={handleLiveRobotSwitch}
          data-testid="robot-control-toggle-live-robot-button"
        >
          <RobotIcon className={styles.icon} />
        </button>

        {!isCameraView && (
          <button
            className={cx(
              styles.label,
              isControllingLiveRobot && styles.labelSelected,
            )}
            onClick={handleLiveRobotSwitch}
          >
            {isControllingLiveRobot && <span>Controlling</span>}
            Live Robot
          </button>
        )}
      </div>

      <div className={styles.buttonGroup}>
        <button
          className={cx(
            styles.button,
            !isControllingLiveRobot && styles.buttonSelected,
          )}
          onClick={handleSimulatedRobotSwitch}
          data-testid="robot-control-toggle-simulation-button"
        >
          <VisualizerIcon className={styles.icon} />
        </button>

        {!isCameraView && (
          <button
            className={cx(
              styles.label,
              !isControllingLiveRobot && styles.labelSelected,
            )}
            onClick={handleSimulatedRobotSwitch}
          >
            {!isControllingLiveRobot && <span>Controlling</span>}
            Visualizer
          </button>
        )}
      </div>

      <RobotControlToggleAlert
        isOpen={isModalOpen}
        onCloseAndSwitch={onCloseModalAndSwitchRobotControl}
        isControllingLiveRobot={isControllingLiveRobot}
        onClose={onCloseModal}
        robotID={robot.id}
      />
    </div>
  );
};

export default RobotControlToggle;
