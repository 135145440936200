import { create } from 'zustand';

import type { ControlModeState } from './shared';

interface ControlModeButtonGroupStore {
  isToolControlTargetMode: boolean;
  isJointControlDualValueMode: boolean;
  onChangeControlMode: (controlMode: ControlModeState) => void;
}

/**
 * Remember the latest dual/single mode for the tool and joint widgets
 * so when they are closed and reopened they show the expected mode
 */
export const useControlModeButtonGroupStore =
  create<ControlModeButtonGroupStore>((set) => ({
    isToolControlTargetMode: false,
    isJointControlDualValueMode: false,
    onChangeControlMode: (controlMode) => {
      switch (controlMode) {
        case 'toolControl':
          set({ isToolControlTargetMode: false });
          break;
        case 'toolControlTarget':
          set({ isToolControlTargetMode: true });
          break;
        case 'jointControlSingle':
          set({ isJointControlDualValueMode: false });
          break;
        case 'jointControlDual':
          set({ isJointControlDualValueMode: true });
          break;
        default:
          break;
      }
    },
  }));
