import { useEffect, useState } from 'react';

import type { ArmJointPositions } from '@sb/motion-planning';
import type { Routine } from '@sb/types';
import { SpaceIcon } from '@sb/ui/icons';
import { useToast } from '@sbrc/hooks';
import type { UseRoutineRunnerHandleArguments } from '@sbrc/hooks';
import { updateRoutine } from '@sbrc/services';

import DisabledWidgetBody from '../widget-panel/DisabledWidgetBody';
import WidgetView from '../widget-panel/WidgetView';

import { EditSpaceItem } from './edit-space-item/EditSpaceItem';
import RenameSpaceItem from './RenameSpaceItem';
import SpaceList from './SpaceList';
import type { WidgetState } from './types';
import { useVisualizeSpace } from './visualize-space/useVisualizeSpace';

interface SpaceWidgetProps {
  onClose: () => void;
  routine: Routine.ConvertedResponse | null;
  isActive: boolean;
  routineRunnerHandleArgs: UseRoutineRunnerHandleArguments;
  setTargetJointAngles: (jointAngles: ArmJointPositions | null) => void;
}

export default function SpaceWidget({
  onClose,
  routine,
  isActive,
  routineRunnerHandleArgs,
  setTargetJointAngles,
}: SpaceWidgetProps) {
  const [widgetState, setWidgetState] = useState<WidgetState>({});

  const { setToast } = useToast();

  useEffect(() => {
    if (!routine) {
      return;
    }

    const { updateItem, removeItemID } = widgetState;

    if (widgetState.updateItem || widgetState.removeItemID) {
      // only run this effect once when the item requires update/remove
      setWidgetState((value) => ({
        ...value,
        updateItem: undefined,
        removeItemID: undefined,
      }));
    }

    const updateSpace = async (space: Routine.SpaceItem[]) => {
      try {
        await updateRoutine({ space }, routine.id);
      } catch (error) {
        setToast({ kind: 'error', message: error.message });
      }
    };

    if (updateItem) {
      const space = [...routine.space];

      const currentIndex = space.findIndex((item) => item.id === updateItem.id);

      if (currentIndex > -1) {
        space[currentIndex] = updateItem;
      } else {
        space.push(updateItem);
      }

      updateSpace(space);
    }

    if (removeItemID) {
      const space = routine.space.filter((item) => item.id !== removeItemID);

      if (space.length < routine.space.length) {
        updateSpace(space);
      }
    }
  }, [widgetState, routine, setToast]);

  useVisualizeSpace({
    widgetState,
    setWidgetState,
    spaceItems: routine?.space,
  });

  if (!isActive) {
    return null;
  }

  if (!routine) {
    return (
      <WidgetView
        headerTitle="Space"
        headerIcon={<SpaceIcon />}
        onClose={onClose}
      >
        <DisabledWidgetBody
          contentHeader="No routine loaded"
          content="Load a routine onto the robot to configure space"
        />
      </WidgetView>
    );
  }

  if (widgetState.createItemKind || widgetState.renameItem) {
    return (
      <RenameSpaceItem
        onClose={onClose}
        widgetState={widgetState}
        setWidgetState={setWidgetState}
        spaceList={[...routine.space]}
      />
    );
  }

  if (widgetState.item) {
    return (
      <EditSpaceItem
        onClose={onClose}
        routineRunnerHandleArgs={routineRunnerHandleArgs}
        spaceItem={widgetState.item}
        widgetState={widgetState}
        setWidgetState={setWidgetState}
        setTargetJointAngles={setTargetJointAngles}
      />
    );
  }

  return (
    <SpaceList
      onClose={onClose}
      setWidgetState={setWidgetState}
      space={routine.space}
      routineName={routine.name}
    />
  );
}
