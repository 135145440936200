import { useRouter } from 'next/router';

import { Button, Typography } from '@sb/ui/components';
import { OpenIcon } from '@sb/ui/icons';
import { getLinkToRobotScenePage } from '@sbrc/utils';

import styles from './GoToRoutineButton.module.css';

interface GoToSceneViewButtonProps {
  robotID: string;
}

const GoToSceneViewButton = ({ robotID }: GoToSceneViewButtonProps) => {
  const { push } = useRouter();

  const onGoToSceneViewClick = () => {
    push(getLinkToRobotScenePage(robotID));
  };

  return (
    <Button
      isFullWidth
      onClick={onGoToSceneViewClick}
      endIcon={<OpenIcon />}
      variant="grayDark"
    >
      <Typography variant="medium" className={styles.buttonLabel}>
        Go to Routine Visualizer
      </Typography>
    </Button>
  );
};

export default GoToSceneViewButton;
