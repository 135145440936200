import type { Routine } from '@sb/types';
import { DropdownButton, MenuItem, Typography } from '@sb/ui/components';
import { AddIcon, ForwardIcon, SpaceIcon } from '@sb/ui/icons';
import { margin } from '@sb/ui/styles';
import { useFeatureFlag } from '@sbrc/hooks';

import WidgetBody from '../widget-panel/WidgetBody';
import WidgetView from '../widget-panel/WidgetView';

import { getSpaceItemKindName } from './getSpaceItemKindName';
import SpaceItemIcon from './SpaceItemIcon';
import SpaceItemSettings from './SpaceItemSettings';
import type { WidgetState } from './types';

import styles from './SpaceList.module.css';

interface SpaceListProps {
  onClose: () => void;
  space: Array<Routine.SpaceItem>;
  setWidgetState: (state: WidgetState) => void;
  routineName: string;
}

const ITEM_KINDS: Routine.SpaceItem['kind'][] = [
  'singlePosition',
  'freeformPositionList',
  'gridPositionList',
  'environmentObject',
];

export default function SpaceList({
  onClose,
  space,
  setWidgetState,
  routineName,
}: SpaceListProps) {
  const enableEnvironmentObjects = useFeatureFlag('environmentObjects');

  return (
    <WidgetView
      onClose={onClose}
      headerTitle="Space"
      headerIcon={<SpaceIcon />}
    >
      <WidgetBody>
        {space.length === 0 && (
          <div className={styles.noItems}>
            Space allows you to define positions and objects around the robot
            that can be referenced in routines. To add items here, tap{' '}
            <strong>“Add”</strong>.
          </div>
        )}

        <div className={styles.list}>
          {space.map((item) => {
            const itemName = (
              <>
                <SpaceItemIcon itemKind={item.kind} />
                <div className={styles.itemName}>
                  <Typography hasNoWrap variant="large" isBold>
                    {item.name}
                  </Typography>
                  <Typography hasNoWrap variant="small" color="gray">
                    {item.description}
                  </Typography>
                </div>
              </>
            );

            if (
              item.kind === 'environmentObject' &&
              !enableEnvironmentObjects
            ) {
              // user can see, but cannot edit
              return (
                <div key={item.id} className={styles.item}>
                  {itemName}
                </div>
              );
            }

            return (
              <div
                key={item.id}
                className={styles.item}
                role="button"
                onClick={() => setWidgetState({ item })}
              >
                {itemName}
                <SpaceItemSettings
                  spaceItem={item}
                  setWidgetState={setWidgetState}
                />
                <ForwardIcon size="extraSmall" />
              </div>
            );
          })}
        </div>

        <div className={styles.footer}>
          <Typography color="gray" variant="small" hasNoWrap>
            {space.length}
            {space.length === 1 ? ' item' : ' items'}
            {` from “${routineName}”`}
          </Typography>

          <DropdownButton
            isFullWidth
            aria-label="New Space Item"
            data-testid="space-widget-new-space-item-button"
            startIcon={<AddIcon className={margin.right.small} />}
            content={ITEM_KINDS.map((itemKind) => {
              if (
                itemKind === 'environmentObject' &&
                !enableEnvironmentObjects
              ) {
                return null;
              }

              return (
                <MenuItem
                  key={itemKind}
                  hasDivider
                  onClick={() => setWidgetState({ createItemKind: itemKind })}
                  startIcon={<SpaceItemIcon itemKind={itemKind} />}
                >
                  {getSpaceItemKindName(itemKind)}
                </MenuItem>
              );
            })}
          />
        </div>
      </WidgetBody>
    </WidgetView>
  );
}
