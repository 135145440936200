import type { Dispatch, SetStateAction } from 'react';
import { createContext, useContext } from 'react';

import type { CartesianPose } from '@sb/geometry';
import type { DeviceCommand } from '@sb/integrations/device';
import type { ArmJointPositions, FrameOfReference } from '@sb/motion-planning';
import type { Robot, Routine } from '@sb/types';
import type { RoutineRunnerHandle } from '@sbrc/services';
import type { EulerPose } from '@sbrc/utils';

export type SyncingRobot =
  | null
  | 'live'
  | 'liveGripper'
  | 'vizbot'
  | 'vizbotGripper';

export type ControlModeState =
  | `equipment-${string}`
  | 'io'
  | 'jointControlSingle'
  | 'jointControlDual'
  | 'settings'
  | 'space'
  | 'syncPositions'
  | 'toolControl'
  | 'toolControlTarget'
  | null;

export type NonIdleState =
  | 'antigravity'
  | 'disconnected'
  | 'recovery'
  | 'routineRunning'
  | 'unbrake'
  | null;

export type TargetJointAnglesState = {
  liveRobot: ArmJointPositions | null;
  vizbot: ArmJointPositions | null;
};

export type TargetPoseState = {
  liveRobot: EulerPose | null;
  vizbot: EulerPose | null;
};

export const ROBOT_CONTROL_MODE_DEFAULT: ControlModeState = 'toolControl';

export interface MoveRobotViewState {
  controlMode: ControlModeState;
  controlViewRoutineRunnerHandle: RoutineRunnerHandle;
  frameOfReference: FrameOfReference;
  gripperCommand: DeviceCommand | null;
  endEffectorDefaultCommand?: DeviceCommand;
  setGripperCommand: React.Dispatch<React.SetStateAction<DeviceCommand | null>>;
  isCameraView: boolean;
  isControllingLiveRobot: boolean;
  isLiveRobotConnected: boolean;
  isModal?: boolean;
  isRealRobotBraked?: boolean | null;
  nonIdleMode?: NonIdleState;
  onSyncPositionComplete?: () => void;
  robot: Robot.ConvertedResponse;
  routine?: Routine.ConvertedResponse | null;
  setControlMode: Dispatch<SetStateAction<ControlModeState>>;
  setFrameOfReference: Dispatch<SetStateAction<FrameOfReference>>;
  setIsCameraView: Dispatch<SetStateAction<boolean>>;
  setIsControllingLiveRobot: Dispatch<SetStateAction<boolean>>;
  setNonIdleMode: Dispatch<SetStateAction<NonIdleState>>;
  setSyncingRobot: Dispatch<SetStateAction<SyncingRobot>>;
  setTargetJointAngles: Dispatch<SetStateAction<TargetJointAnglesState>>;
  setTargetPose: Dispatch<SetStateAction<TargetPoseState>>;
  syncingRobot: SyncingRobot;
  targetJointAngles: TargetJointAnglesState;
  targetPose: TargetPoseState;
}

export const MoveRobotViewContext = createContext<MoveRobotViewState>({
  controlMode: 'toolControl',
  controlViewRoutineRunnerHandle: {} as RoutineRunnerHandle,
  frameOfReference: 'tooltip',
  gripperCommand: null,
  setGripperCommand: (
    _command: React.SetStateAction<DeviceCommand | null>,
  ) => {},
  isCameraView: false,
  isControllingLiveRobot: false,
  isLiveRobotConnected: true,
  isRealRobotBraked: true,
  nonIdleMode: null,
  robot: {} as Robot.ConvertedResponse,
  setControlMode: () => {},
  setFrameOfReference: () => {},
  setIsCameraView: () => {},
  setIsControllingLiveRobot: () => {},
  setNonIdleMode: () => {},
  setSyncingRobot: () => {},
  setTargetJointAngles: () => {},
  setTargetPose: () => {},
  syncingRobot: null,
  targetJointAngles: { liveRobot: null, vizbot: null },
  targetPose: { liveRobot: null, vizbot: null },
});

export const useMoveRobotViewContext = () => useContext(MoveRobotViewContext);

export const getCurrentJointAngles = (
  routineRunnerHandle: RoutineRunnerHandle,
): ArmJointPositions | null => {
  return routineRunnerHandle.getState()?.kinematicState.jointAngles ?? null;
};

export const getCurrentCartesianPose = (
  routineRunnerHandle: RoutineRunnerHandle,
): CartesianPose | null => {
  return routineRunnerHandle.getState()?.kinematicState.tooltipPoint ?? null;
};

// Don't show a negative sign when the value is 0
export const angleToStringWithPositiveZero = (
  value: number,
  toFixedPrecision: number,
) => {
  return value.toFixed(toFixedPrecision).replace(/^-([0.]+)$/, '$1');
};

export const JOINT_ANGLE_PRECISION = 1;
