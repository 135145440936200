import type { Routine } from '@sb/types';
import {
  PositionListBlankIcon,
  PositionListGridIcon,
  PositionListSingleIcon,
  SandboxIcon,
  SpaceIcon,
} from '@sb/ui/icons';
import type { IconProps } from '@sb/ui/icons';

interface SpaceItemIconProps extends IconProps {
  itemKind: Routine.SpaceItem['kind'];
}

export default function SpaceItemIcon({
  itemKind,
  ...iconProps
}: SpaceItemIconProps) {
  switch (itemKind) {
    case 'freeformPositionList':
      return <PositionListBlankIcon size="small" {...iconProps} />;

    case 'singlePosition':
      return <PositionListSingleIcon size="small" {...iconProps} />;

    case 'gridPositionList':
      return <PositionListGridIcon size="small" {...iconProps} />;

    case 'environmentObject':
      return <SandboxIcon size="small" {...iconProps} />;

    default: {
      const exhaustive: never = itemKind; // ensure type exhaustive

      return <SpaceIcon aria-label={exhaustive} size="small" {...iconProps} />;
    }
  }
}
