import { IOWidget } from '../../visualizer-view-shared/io/IOWidget';
import { useMoveRobotViewContext } from '../shared';

const MoveRobotIOWidget = () => {
  const { isControllingLiveRobot, robot, setControlMode } =
    useMoveRobotViewContext();

  return (
    <IOWidget
      isVizbot={!isControllingLiveRobot}
      robot={robot}
      onClose={() => setControlMode(null)}
    />
  );
};

export default MoveRobotIOWidget;
