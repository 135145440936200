import { Button } from '@sb/ui/components';
import { BrakeIcon, SettingsIcon } from '@sb/ui/icons';
import { useRobotUnbrake } from '@sbrc/hooks';

import WidgetView from '../../visualizer-view-shared/widget-panel/WidgetView';
import { useMoveRobotViewContext } from '../shared';

import styles from './MoveRobotSettingsWidget.module.css';

const MoveRobotSettingsWidget = () => {
  const { isControllingLiveRobot, robot, setControlMode } =
    useMoveRobotViewContext();

  const { brakeRobot: brakeControlViewRobot } = useRobotUnbrake({
    robotID: robot.id,
    isVizbot: !isControllingLiveRobot,
  });

  return (
    <WidgetView
      onClose={() => setControlMode(null)}
      hasNoBorder
      headerTitle="Settings"
      headerIcon={<SettingsIcon />}
      testPrefix="settings"
    >
      <div className={styles.section}>
        <Button
          variant="alertPrimary"
          startIcon={<BrakeIcon />}
          isFullWidth
          onClick={brakeControlViewRobot}
          data-testid="move-robot-control-panel-widget-settings-brake-button"
        >
          Brake Robot
        </Button>
      </div>
    </WidgetView>
  );
};

export default MoveRobotSettingsWidget;
