import { useEffect } from 'react';

import { useIsRobotBraked, useRobotStateKind } from '@sbrc/hooks';

import { useMoveRobotViewContext } from '../shared';

const useUpdateNonIdleState = (): void => {
  const {
    controlMode,
    isControllingLiveRobot,
    isLiveRobotConnected,
    robot,
    setNonIdleMode,
  } = useMoveRobotViewContext();

  const robotID = robot.id;

  const isRealRobotBraked = useIsRobotBraked({ robotID });

  const isVizbotBraked = useIsRobotBraked({
    robotID,
    isVizbot: true,
  });

  const isControlViewRobotBraked = isControllingLiveRobot
    ? isRealRobotBraked
    : isVizbotBraked;

  const realRoutineRunnerStateKind = useRobotStateKind({
    robotID,
  });

  const simulatedRoutineRunnerStateKind = useRobotStateKind({
    robotID,
    isVizbot: true,
  });

  const controlViewRoutineRunnerKind = useRobotStateKind({
    robotID,
    isVizbot: !isControllingLiveRobot,
  });

  const isRealRoutineRunning =
    isControllingLiveRobot && realRoutineRunnerStateKind === 'RoutineRunning';

  const isFailureDuringSyncPositions =
    controlMode === 'syncPositions' &&
    (realRoutineRunnerStateKind === 'Failure' ||
      simulatedRoutineRunnerStateKind === 'Failure');

  const isAntigravity =
    isControllingLiveRobot && realRoutineRunnerStateKind === 'Antigravity';

  /**
   * When controlling the live robot, we should only display the recovery panel if
   * the live robot is in failure mode.
   *
   * The same logic applies to Vizbot: the recovery panel should only
   * be visible when the Vizbot is in failure mode.
   *
   * However, when we're syncing positions, we should display the recovery panel
   * if either the live robot OR Vizbot is in failure state.
   */
  const isFailureState =
    isFailureDuringSyncPositions ||
    controlViewRoutineRunnerKind === 'Failure' ||
    controlViewRoutineRunnerKind === 'Recovering';

  useEffect(() => {
    if (isControllingLiveRobot && !isLiveRobotConnected) {
      setNonIdleMode('disconnected');

      return;
    }

    if (isAntigravity) {
      setNonIdleMode('antigravity');

      return;
    }

    if (isFailureState) {
      setNonIdleMode('recovery');

      return;
    }

    if (isControlViewRobotBraked) {
      setNonIdleMode('unbrake');

      return;
    }

    if (controlViewRoutineRunnerKind === 'Idle') {
      setNonIdleMode(null);

      return;
    }

    if (isRealRoutineRunning) {
      setNonIdleMode('routineRunning');
    }
  }, [
    controlViewRoutineRunnerKind,
    isAntigravity,
    isControlViewRobotBraked,
    isControllingLiveRobot,
    isFailureState,
    isLiveRobotConnected,
    isRealRobotBraked,
    isRealRoutineRunning,
    setNonIdleMode,
  ]);
};

export default useUpdateNonIdleState;
