import { useEffect } from 'react';

import type { ArmJointPositions } from '@sb/motion-planning';
import type { Routine } from '@sb/types';
import { Button, Typography } from '@sb/ui/components';
import { AddIcon, ArrowBackIcon } from '@sb/ui/icons';
import { useIsRobotAtJointAngles, useRoutineRunnerHandle } from '@sbrc/hooks';
import { getRobotPosition } from '@sbrc/utils';

import WidgetBody from '../../widget-panel/WidgetBody';
import WidgetView from '../../widget-panel/WidgetView';
import SpaceItemIcon from '../SpaceItemIcon';
import type { WidgetState } from '../types';
import { UNSAVED_POSITION } from '../types';

import { EditPositionItem } from './EditPositionItem';
import type { EditSpaceItemProps } from './types';

import styles from './EditPositionList.module.css';

export function EditPositionList({
  onClose,
  routineRunnerHandleArgs,
  spaceItem,
  widgetState,
  setWidgetState,
  setTargetJointAngles,
  children,
}: React.PropsWithChildren<EditSpaceItemProps>) {
  const routineRunnerHandle = useRoutineRunnerHandle(routineRunnerHandleArgs);

  const { positions } = spaceItem;

  const { currentPositionIndex = 0 } = widgetState;

  const currentPositionJointAngles: ArmJointPositions | null =
    routineRunnerHandleArgs.isVizbot
      ? null
      : positions[currentPositionIndex]?.jointAngles ?? null;

  const isRobotAtAnyPositionInList = useIsRobotAtJointAngles(
    routineRunnerHandleArgs,
    ...positions.map((p) => p.jointAngles),
  );

  useEffect(() => {
    setTargetJointAngles(currentPositionJointAngles);
  }, [currentPositionJointAngles, setTargetJointAngles]);

  useEffect(() => {
    return () => setTargetJointAngles(null);
  }, [setTargetJointAngles]);

  const handleSelectPosition = (index: number) => {
    setWidgetState({ ...widgetState, currentPositionIndex: index });
  };

  const handleAddPosition = (index: number, position: 'before' | 'after') => {
    const newPositions = [...positions];

    const offset = position === 'before' ? 0 : 1;
    const newIndex = index + offset;
    newPositions.splice(newIndex, 0, UNSAVED_POSITION);

    setWidgetState({
      ...widgetState,
      item: {
        ...spaceItem,
        positions: newPositions,
      },
      currentPositionIndex: newIndex,
    });
  };

  const updatePositions = (
    newPositions: Routine.Position[],
    state?: Partial<WidgetState>,
  ) => {
    setWidgetState({
      ...widgetState,
      ...state,
      item: {
        ...spaceItem,
        positions: newPositions,
      },
      updateItem: {
        ...spaceItem,
        positions: newPositions.filter((p) => p !== UNSAVED_POSITION),
      },
    });
  };

  const handleSavePosition = (index: number) => {
    const robotPosition = getRobotPosition(routineRunnerHandle);

    if (robotPosition) {
      const newPositions = [...positions];
      newPositions[index] = robotPosition;
      updatePositions(newPositions);
    }
  };

  const isRemoveDisabled = positions.length <= 1;

  const handleDeletePosition = (index: number) => {
    if (isRemoveDisabled) {
      return;
    }

    const newPositions = [...positions];
    newPositions.splice(index, 1);

    updatePositions(newPositions, {
      currentPositionIndex: Math.min(newPositions.length - 1, index),
    });
  };

  const handleMovePosition = (index: number, newIndex: number) => {
    const newPositions = [...positions];
    const positionToMove = newPositions.splice(index, 1);
    newPositions.splice(newIndex, 0, ...positionToMove);
    updatePositions(newPositions, { currentPositionIndex: newIndex });
  };

  return (
    <WidgetView
      onClose={onClose}
      headerTitle={spaceItem.name}
      headerIcon={
        <>
          <Button
            aria-label="Back"
            data-testid="space-widget-header-back-button"
            variant="gray"
            startIcon={<ArrowBackIcon />}
            size="extraSmall"
            onClick={() => setWidgetState({})}
          />
          <SpaceItemIcon itemKind={spaceItem.kind} size="small" />
        </>
      }
    >
      <WidgetBody>
        <div className={styles.positionList}>
          {positions.map((position, index) => {
            const key = `${index}`;

            return (
              <EditPositionItem
                key={key}
                id={index}
                position={position}
                isSelected={currentPositionIndex === index}
                isUnsaved={position === UNSAVED_POSITION}
                isRobotAtAnyPositionInList={isRobotAtAnyPositionInList}
                onSelect={() => handleSelectPosition(index)}
                onAddBefore={() => handleAddPosition(index, 'before')}
                onAddAfter={() => handleAddPosition(index, 'after')}
                onSave={() => handleSavePosition(index)}
                isRemoveDisabled={isRemoveDisabled}
                onRemove={() => handleDeletePosition(index)}
                isMoveUpDisabled={index === 0}
                onMoveUp={() => handleMovePosition(index, index - 1)}
                isMoveDownDisabled={index === positions.length - 1}
                onMoveDown={() => handleMovePosition(index, index + 1)}
                routineRunnerHandleArgs={routineRunnerHandleArgs}
              />
            );
          })}
        </div>

        <div className={styles.footer}>
          <Typography color="gray" variant="small">
            {positions.length}{' '}
            {positions.length === 1 ? 'position' : 'positions'}
          </Typography>
          <Button onClick={() => handleAddPosition(positions.length, 'before')}>
            <AddIcon size="small" />
          </Button>
        </div>
        {children}
      </WidgetBody>
    </WidgetView>
  );
}
