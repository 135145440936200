import type { ArmJointPositions } from '@sb/motion-planning';
import {
  convertDegreesToRadians,
  convertRadiansToDegrees,
} from '@sb/utilities';

import { getCurrentJointAngles, useMoveRobotViewContext } from '../../shared';

export default function useTargetJointAngles() {
  const {
    controlViewRoutineRunnerHandle,
    isControllingLiveRobot,
    setTargetJointAngles,
    targetJointAngles,
  } = useMoveRobotViewContext();

  const targetKey = isControllingLiveRobot ? 'liveRobot' : 'vizbot';

  const setTargetAngle = (
    index: number,
  ): React.Dispatch<React.SetStateAction<number>> => {
    return (targetAngle) => {
      setTargetJointAngles((previousState) => {
        const previousTarget: ArmJointPositions | null =
          previousState[targetKey];

        const newTarget: ArmJointPositions = previousTarget
          ? [...previousTarget]
          : getCurrentJointAngles(controlViewRoutineRunnerHandle)!;

        const newTargetAngle =
          typeof targetAngle === 'function'
            ? targetAngle(convertRadiansToDegrees(newTarget[index]))
            : targetAngle;

        newTarget[index] = convertDegreesToRadians(newTargetAngle);

        return { ...previousState, [targetKey]: newTarget };
      });
    };
  };

  const resetAllTargetAngles = () => {
    setTargetJointAngles((previousState) => {
      return {
        ...previousState,
        [targetKey]: null,
      };
    });
  };

  const setAllTargetJointAngles = (newTarget: ArmJointPositions) => {
    setTargetJointAngles((previousState) => {
      return {
        ...previousState,
        [targetKey]: newTarget,
      };
    });
  };

  return {
    setAllTargetJointAngles,
    resetAllTargetAngles,
    setTargetAngle,
    targetJointAngles: targetJointAngles[targetKey],
  };
}
