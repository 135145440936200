import type { DeviceCommand } from '@sb/integrations/device';
import type { ArmPosition } from '@sb/routine-runner';
import { Button } from '@sb/ui/components';
import { TickIcon } from '@sb/ui/icons';
import {
  useRoutineRunnerHandle,
  useRobotStateKind,
  useIsRobotMoving,
} from '@sbrc/hooks';

import { AppHeaderNav, NavAndTitle } from '../header';

import { useMoveRobotViewContext } from './shared';

interface MoveRobotHeaderNavProps {
  onConfirmArmPosition?: (position: ArmPosition) => void;
  onConfirmEndEffector?: (command: DeviceCommand, payloadKg?: number) => void;
  onReturn: () => void;
  isOnConfirmDisabled?: boolean;
}

export default function MoveRobotHeaderNav({
  onConfirmArmPosition,
  onConfirmEndEffector,
  onReturn,
  isOnConfirmDisabled,
}: MoveRobotHeaderNavProps) {
  const {
    isControllingLiveRobot,
    isModal,
    robot: selectedRobot,
    gripperCommand,
  } = useMoveRobotViewContext();

  const canConfirmGripperPosition = !gripperCommand;

  const { id, name } = selectedRobot;

  const routineRunnerArgs = {
    robotID: id,
    isVizbot: !isControllingLiveRobot,
  };

  const routineRunnerHandle = useRoutineRunnerHandle(routineRunnerArgs);

  const routineRunnerStateKind = useRobotStateKind(routineRunnerArgs);

  const { isRobotMoving } = useIsRobotMoving(routineRunnerArgs);

  const isStepConfiguration = onConfirmEndEffector || onConfirmArmPosition;

  /** Button is disabled when we don't have the routine runner state or the robot is running. */
  const isConfirmButtonDisabled =
    !routineRunnerStateKind ||
    isRobotMoving ||
    isOnConfirmDisabled ||
    (onConfirmEndEffector && canConfirmGripperPosition);

  const onConfirmPositionClick = () => {
    const confirmPosition = () => {
      const routineRunnerState = routineRunnerHandle.getState();
      const kinematicState = routineRunnerState?.kinematicState;

      const pose = kinematicState?.tooltipPoint;
      const jointAngles = kinematicState?.jointAngles;

      if (onConfirmArmPosition && pose && jointAngles) {
        onConfirmArmPosition({ pose, jointAngles });
      }

      const payloadKg = routineRunnerState?.configuration.payload.mass;

      if (onConfirmEndEffector && gripperCommand) {
        onConfirmEndEffector(gripperCommand, payloadKg);
      }
    };

    confirmPosition();
  };

  const onBackButtonActionClick = () => {
    onReturn();
  };

  if (isModal) {
    return (
      <>
        <NavAndTitle
          isHomeButtonHidden={isModal}
          onReturn={onBackButtonActionClick}
          title={isStepConfiguration ? '' : `Move ${name}`}
        />

        {isStepConfiguration && (
          <Button
            disabled={isConfirmButtonDisabled}
            onClick={onConfirmPositionClick}
            startIcon={<TickIcon />}
          >
            Confirm {onConfirmArmPosition ? 'Arm' : 'End Effector'} Position
          </Button>
        )}
      </>
    );
  }

  return <AppHeaderNav robotID={id} activePage="moveRobot" />;
}
