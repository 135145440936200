import cx from 'classnames';

import { Button } from '@sb/ui/components';
import { SyncIcon } from '@sb/ui/icons';
import { useRobotStateKind } from '@sbrc/hooks';

import { useIsRobotSynced, useMoveRobotViewContext } from './shared';

import styles from './SyncPositionsButton.module.css';

const SyncPositionsButton = () => {
  const {
    controlMode,
    isCameraView,
    isLiveRobotConnected,
    robot,
    setControlMode,
  } = useMoveRobotViewContext();

  const webRTCRoutineRunnerStateKind = useRobotStateKind({
    robotID: robot.id,
  });

  const vizbotRoutineRunnerStateKind = useRobotStateKind({
    robotID: robot.id,
    isVizbot: true,
  });

  const { isPositionsSynced } = useIsRobotSynced();

  /**  Sync Positions Button Enabled Criteria:
   * - The live robot is connected
   * - The live robot's position can be retrieved from the routine runner
   * - The live robot's joint angle position is different from Vizbot's joint angle position
   * - The robot is not currently running an routine
   * - When the user is activley in the sync positions panel
   * - The live robot is not in failure
   */
  const isSyncPositionsHidden: boolean =
    !isLiveRobotConnected ||
    webRTCRoutineRunnerStateKind === 'RoutineRunning' ||
    webRTCRoutineRunnerStateKind === 'Failure' ||
    vizbotRoutineRunnerStateKind === 'Failure' ||
    !webRTCRoutineRunnerStateKind ||
    (controlMode !== 'syncPositions' && isPositionsSynced);

  return (
    <div
      className={cx(styles.syncPositionContainer, {
        [styles.hidden]: isCameraView || isSyncPositionsHidden,
      })}
    >
      <Button
        className={cx(styles.syncPositionButton, {
          [styles.buttonSelected]: controlMode === 'syncPositions',
        })}
        onClick={() => {
          setControlMode(
            controlMode === 'syncPositions' ? null : 'syncPositions',
          );
        }}
        startIcon={
          <SyncIcon
            className={cx(styles.icon, {
              [styles.iconSelected]: controlMode === 'syncPositions',
            })}
          />
        }
        data-testid="move-robot-sync-position-button"
      />
      <button
        className={cx(styles.label, {
          [styles.labelSelected]: controlMode === 'syncPositions',
        })}
        onClick={() => {
          setControlMode(
            controlMode === 'syncPositions' ? null : 'syncPositions',
          );
        }}
      >
        Sync Positions
      </button>
    </div>
  );
};

export default SyncPositionsButton;
