import WidgetPanel from '@sbrc/components/visualizer-view-shared/widget-panel/WidgetPanel';

import { useMoveRobotViewContext } from '../shared';

import AntigravityMode from './AntigravityMode';
import ControlModeSyncPositionsView from './ControlModeSyncPositionsView';
import NonIdleModeDisconnected from './NonIdleModeDisconnected';
import NonIdleModeRecoveryView from './NonIdleModeRecoveryView';
import NonIdleModeRoutineRunningView from './NonIdleModeRoutineRunningView';
import NonIdleModeUnbrakeView from './NonIdleModeUnbrakeView';
import useUpdateNonIdleState from './useUpdateNonIdleState';

const NonIdleMoveView = () => {
  useUpdateNonIdleState();

  const { controlMode, nonIdleMode, routine } = useMoveRobotViewContext();

  return (
    <WidgetPanel position="top-right">
      {nonIdleMode === 'routineRunning' && routine && (
        <NonIdleModeRoutineRunningView routine={routine} />
      )}

      {nonIdleMode === 'unbrake' && <NonIdleModeUnbrakeView />}

      {nonIdleMode === 'antigravity' && <AntigravityMode />}

      {nonIdleMode === 'disconnected' && <NonIdleModeDisconnected />}

      {nonIdleMode === null && controlMode === 'syncPositions' && (
        <ControlModeSyncPositionsView />
      )}

      {nonIdleMode === 'recovery' && <NonIdleModeRecoveryView />}
    </WidgetPanel>
  );
};

export default NonIdleMoveView;
