import GoToRoutineButton from '@sbrc/components/visualizer-view-shared/GoToRoutineButton';
import RecoveryWidget from '@sbrc/components/visualizer-view-shared/recovery/RecoveryWidget';

import { useMoveRobotViewContext } from '../shared';

import styles from './NonIdleModeRecoveryView.module.css';

const NonIdleModeRecoveryView = () => {
  const { isControllingLiveRobot, robot, routine } = useMoveRobotViewContext();

  return (
    <div>
      {routine && (
        <GoToRoutineButton
          className={styles.goToRoutineEditorButtom}
          robotID={robot.id}
          routineID={String(routine.id)}
          routineName={String(routine.name)}
        />
      )}

      <RecoveryWidget isVizbot={!isControllingLiveRobot} robot={robot} />
    </div>
  );
};

export default NonIdleModeRecoveryView;
