import cx from 'classnames';

import { Typography } from '@sb/ui/components';

import { angleToStringWithPositiveZero } from './shared';

import styles from './ToolCoordinate.module.css';

interface ToolCoordinateProps {
  color?: 'X' | 'Y' | 'Z';
  coordinateLabel: string;
  decimalPlaces: number;
  unitLabel?: string;
  value: number;
}

const ToolCoordinate = ({
  color,
  coordinateLabel,
  decimalPlaces,
  unitLabel,
  value,
}: ToolCoordinateProps) => {
  const valueString = Number.isNaN(value)
    ? 'Loading...'
    : angleToStringWithPositiveZero(value, decimalPlaces);

  return (
    <div className={styles.toolCoordinate}>
      <Typography
        className={cx(styles.label, color && styles[`color${color}`])}
        variant="medium"
        isBold
      >
        {coordinateLabel}
      </Typography>

      <div className={styles.valueContainer}>
        <Typography className={styles.value}>{valueString}</Typography>

        {unitLabel && (
          <Typography className={styles.unit}>{unitLabel}</Typography>
        )}
      </div>
    </div>
  );
};

export default ToolCoordinate;
