import { EquipmentWidget } from '../../visualizer-view-shared/widget-panel/EquipmentWidget';
import { useMoveRobotViewContext } from '../shared';

export function MoveRobotEquipmentWidget() {
  const {
    controlMode,
    isControllingLiveRobot,
    robot,
    setControlMode,
    setGripperCommand,
    endEffectorDefaultCommand,
  } = useMoveRobotViewContext();

  return (
    <EquipmentWidget
      robot={robot}
      isVizbot={!isControllingLiveRobot}
      activeWidget={controlMode}
      onClose={() => setControlMode(null)}
      onCommandChange={setGripperCommand}
      endEffectorDefaultCommand={endEffectorDefaultCommand}
    />
  );
}
