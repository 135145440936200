import type { Robot } from '@sb/types';
import { ToggleButton, ToggleButtonGroup } from '@sb/ui/components';
import { useRobotStateKind } from '@sbrc/hooks';

import { useMoveRobotViewContext } from './shared';

import styles from './FrameOfReferenceToggle.module.css';

interface FrameOfReferenceToggleProps {
  onChange: (frameOfReference: Robot.FrameOfReference) => void;
  value: Robot.FrameOfReference;
}

const FrameOfReferenceToggle = ({
  onChange,
  value,
}: FrameOfReferenceToggleProps) => {
  const { isControllingLiveRobot, robot } = useMoveRobotViewContext();

  const routineRunnerArgs = {
    robotID: robot.id,
    isVizbot: !isControllingLiveRobot,
  };

  const routineRunnerStateKind = useRobotStateKind(routineRunnerArgs);

  /** Disable these buttons until we're able to get the routine runner state. */
  const isDisabled = !routineRunnerStateKind;

  return (
    <ToggleButtonGroup>
      <ToggleButton
        disabled={isDisabled}
        isSelected={value === 'tooltip'}
        onClick={() => onChange('tooltip')}
        className={styles.toggleButton}
        data-testid="move-robot-frame-of-reference-align-tool-toggle"
      >
        Align with Tool
      </ToggleButton>
      <ToggleButton
        disabled={isDisabled}
        isSelected={value === 'robotArm'}
        onClick={() => onChange('robotArm')}
        className={styles.toggleButton}
        data-testid="move-robot-frame-of-reference-align-base-toggle"
      >
        Align with Base
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default FrameOfReferenceToggle;
