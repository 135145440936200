/* eslint-disable jsx-a11y/media-has-caption, @next/next/no-img-element */

import cx from 'classnames';
import { useState } from 'react';

import { Typography } from '@sb/ui/components';
import { RotateLeftIcon, RotateRightIcon } from '@sb/ui/icons';

import styles from './RobotLiveStream.module.css';

const totalRotation = 360;

const rotationPerClick = 90;

interface RobotLiveStreamProps {
  className?: string;
  hasBorderRadius?: boolean;
  hasShadow?: boolean;
  isRotationEnabled?: boolean;
}

export function RobotLiveStream({
  className,
  hasBorderRadius = false,
  hasShadow = false,
  isRotationEnabled = false,
}: RobotLiveStreamProps) {
  const [imageElement, setImageElement] = useState<HTMLImageElement | null>(
    null,
  );

  const [currentRotation, setRotation] = useState<number>(0);

  const isRotatedVertically: boolean =
    Math.abs(currentRotation) % (totalRotation / 2) === 90;

  /** Calculate the bottom position when the video is vertically rotated. */
  const rotateControlBottomPosition: number =
    (Number(imageElement?.clientWidth) - Number(imageElement?.clientHeight)) /
      2 -
    10;

  return (
    <div className={cx(styles.robotLiveStream, className)}>
      <div
        className={cx(styles.robotLiveStream, {
          [styles.hasBorderRadius]: hasBorderRadius,
          [styles.hasShadow]: hasShadow,
        })}
        style={{ transform: `rotate(${currentRotation}deg)` }}
      >
        <img
          ref={setImageElement}
          src="/camera-bot-api/camera/stream/rgb"
          alt="Camera stream"
        />
      </div>

      {isRotationEnabled && (
        <div
          className={styles.rotationButtonGroup}
          style={{
            bottom: isRotatedVertically
              ? `-${rotateControlBottomPosition}px`
              : undefined,
          }}
        >
          <button
            className={styles.rotationButton}
            onClick={() => {
              setRotation((currentRotation - rotationPerClick) % totalRotation);
            }}
          >
            <RotateLeftIcon size="small" />
          </button>

          <Typography isBold variant="medium" className={styles.rotationLabel}>
            {currentRotation}°
          </Typography>

          <button
            className={styles.rotationButton}
            onClick={() => {
              setRotation((currentRotation + rotationPerClick) % totalRotation);
            }}
          >
            <RotateRightIcon size="small" />
          </button>
        </div>
      )}
    </div>
  );
}
