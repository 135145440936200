import { clamp } from 'lodash';
import React, { useRef, useState } from 'react';

import type { Routine } from '@sb/types';
import { Button, NumberInput, Typography } from '@sb/ui/components';
import { ArrowBackIcon } from '@sb/ui/icons';
import { display, margin } from '@sb/ui/styles';
import {
  useIsRobotAtJointAngles,
  useRoutineRunnerHandle,
  useToast,
} from '@sbrc/hooks';
import { getRobotPosition } from '@sbrc/utils';

import WidgetBody from '../../widget-panel/WidgetBody';
import WidgetView from '../../widget-panel/WidgetView';
import SpaceItemIcon from '../SpaceItemIcon';

import { EditGridCorner } from './EditGridCorner';
import { EditPositionList } from './EditPositionList';
import generateGridPositions from './generateGridPositions';
import { GridDiagram } from './GridDiagram';
import type { EditSpaceItemProps, GridCorner } from './types';
import { useGetSyncButtonProps } from './useGetSyncButtonProps';

import styles from './EditGridPositionList.module.css';

const CORNERS: Array<{ corner: GridCorner; label: string }> = [
  { corner: 'cornerA', label: 'Corner A' },
  { corner: 'cornerB', label: 'Corner B' },
  { corner: 'cornerC', label: 'Corner C' },
  { corner: 'cornerD', label: 'Corner D' },
];

const MIN_ITEMS = 1;
const MAX_ITEMS = 100;

export function EditGridPositionList({
  onClose,
  routineRunnerHandleArgs,
  spaceItem,
  widgetState,
  setWidgetState,
  setTargetJointAngles,
}: EditSpaceItemProps<Routine.GridPositionList>) {
  const routineRunnerHandle = useRoutineRunnerHandle(routineRunnerHandleArgs);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const abortSubmitRef = useRef<AbortController | null>(null);

  const [positionsValidatedCount, setPositionsValidatedCount] =
    useState<number>(0);

  const { setToast } = useToast();

  const getSyncButtonProps = useGetSyncButtonProps(routineRunnerHandleArgs);

  const isRobotAt = {
    cornerA: useIsRobotAtJointAngles(
      routineRunnerHandleArgs,
      spaceItem.cornerA?.jointAngles,
    ),
    cornerB: useIsRobotAtJointAngles(
      routineRunnerHandleArgs,
      spaceItem.cornerB?.jointAngles,
    ),
    cornerC: useIsRobotAtJointAngles(
      routineRunnerHandleArgs,
      spaceItem.cornerC?.jointAngles,
    ),
    cornerD: useIsRobotAtJointAngles(
      routineRunnerHandleArgs,
      spaceItem.cornerD?.jointAngles,
    ),
  };

  const isRobotAtAnyCorner =
    isRobotAt.cornerA ||
    isRobotAt.cornerB ||
    isRobotAt.cornerC ||
    isRobotAt.cornerD;

  const isNew = spaceItem.positions.length === 0;

  if (!isNew && !widgetState.isSettingCorners) {
    return (
      <EditPositionList
        onClose={onClose}
        routineRunnerHandleArgs={routineRunnerHandleArgs}
        spaceItem={spaceItem}
        widgetState={widgetState}
        setWidgetState={setWidgetState}
        setTargetJointAngles={setTargetJointAngles}
      >
        <Button
          type="button"
          variant="secondary"
          className={margin.top.medium}
          onClick={() =>
            setWidgetState({ ...widgetState, isSettingCorners: true })
          }
        >
          Regenerate Grid
        </Button>
      </EditPositionList>
    );
  }

  const handleSaveCorner = (corner: GridCorner) => () => {
    const robotPosition = getRobotPosition(routineRunnerHandle);

    if (robotPosition) {
      const item = {
        ...spaceItem,
        [corner]: robotPosition,
      };

      setWidgetState({
        ...widgetState,
        item,
        // new corner will be saved after the grid is generated
        updateItem: isNew ? item : undefined,
      });
    }
  };

  const handleClearCorner = (corner: GridCorner) => () => {
    const item = {
      ...spaceItem,
      [corner]: null,
    };

    setWidgetState({
      ...widgetState,
      item,
      // new corner will be saved after the grid is generated
      updateItem: isNew ? item : undefined,
    });
  };

  const handleChangeDimension =
    (dimension: 'numRows' | 'numColumns') => (value: number) => {
      const item = {
        ...spaceItem,
        [dimension]: clamp(value, MIN_ITEMS, MAX_ITEMS),
      };

      setWidgetState({
        ...widgetState,
        item,
        // new dimension will be saved after the grid is generated
        updateItem: isNew ? item : undefined,
      });
    };

  const handleSubmit: React.FormEventHandler = async (event) => {
    event.preventDefault();

    setPositionsValidatedCount(0);
    setIsSubmitting(true);

    const abortController = new AbortController();
    abortSubmitRef.current?.abort();
    abortSubmitRef.current = abortController;

    try {
      const item = await generateGridPositions(
        spaceItem,
        () => setPositionsValidatedCount((n) => n + 1),
        abortController.signal,
      );

      if (!abortController.signal.aborted) {
        setWidgetState({
          ...widgetState,
          item,
          updateItem: item,
          currentPositionIndex: undefined,
          isSettingCorners: undefined,
        });
      }
    } catch (error) {
      if (!abortController.signal.aborted) {
        setToast({ kind: 'error', message: error.message });
      }
    }

    setPositionsValidatedCount(0);
    setIsSubmitting(false);
  };

  const handleGoBack = () => {
    abortSubmitRef.current?.abort();

    if (isNew) {
      // back to space list
      setWidgetState({});
    } else {
      // back to position list
      setWidgetState({ ...widgetState, isSettingCorners: false });
    }
  };

  const unsavedCorners = CORNERS.map(({ corner }) => corner).filter(
    (corner) => !spaceItem[corner],
  );

  const areThreeCornersSaved = unsavedCorners.length === 1;

  const isSubmitDisabled = !areThreeCornersSaved || isSubmitting;

  return (
    <WidgetView
      onClose={onClose}
      headerTitle={spaceItem.name}
      headerIcon={
        <>
          <Button
            aria-label="Back"
            data-testid="space-widget-header-back-button"
            variant="gray"
            startIcon={<ArrowBackIcon />}
            size="extraSmall"
            onClick={handleGoBack}
          />
          <SpaceItemIcon itemKind={spaceItem.kind} />
        </>
      }
    >
      <WidgetBody>
        <form className={display.flex.column}>
          <Typography
            isUppercase
            variant="small"
            isBold
            className={margin.bottom.small}
          >
            Size & Position
          </Typography>

          <div className={display.flex.row}>
            <Typography className={margin.bottom.small}>
              Set three corner positions to specify the size, location and
              orientation of the grid.
            </Typography>

            <GridDiagram
              numColumns={spaceItem.numColumns}
              numRows={spaceItem.numRows}
              unsavedCorners={unsavedCorners}
            />
          </div>

          <Typography>
            To do this: for each corner, move the arm to the corner position
            then return to this panel and tap the “Set” button.
          </Typography>

          <div className={styles.cornersList}>
            {CORNERS.map(({ corner, label }) => (
              <EditGridCorner
                key={corner}
                label={label}
                isUnsaved={unsavedCorners.includes(corner)}
                areThreeCornersSaved={areThreeCornersSaved}
                isRobotAtAnyCorner={isRobotAtAnyCorner}
                isSubmitting={isSubmitting}
                syncButtonProps={getSyncButtonProps(
                  spaceItem[corner]?.jointAngles,
                  isRobotAt[corner] || unsavedCorners.includes(corner),
                )}
                handleClearCorner={handleClearCorner(corner)}
                handleSaveCorner={handleSaveCorner(corner)}
              />
            ))}
          </div>

          <Typography
            isUppercase
            variant="small"
            isBold
            className={margin.bottom.small}
          >
            Layout
          </Typography>

          <div className={display.flex.row}>
            <NumberInput
              value={spaceItem.numRows}
              onChange={handleChangeDimension('numRows')}
              disabled={isSubmitting}
              decimalPlaces={0}
              step={1}
              min={MIN_ITEMS}
              max={MAX_ITEMS}
              helperText="(between A & B)"
            >
              Rows
            </NumberInput>

            <span className={styles.multiplySymbol}>×</span>

            <NumberInput
              value={spaceItem.numColumns}
              onChange={handleChangeDimension('numColumns')}
              disabled={isSubmitting}
              decimalPlaces={0}
              step={1}
              min={MIN_ITEMS}
              max={MAX_ITEMS}
              helperText="(between A and D)"
            >
              Columns
            </NumberInput>
          </div>

          <Button
            type="submit"
            disabled={isSubmitDisabled}
            onClick={handleSubmit}
            className={margin.top.medium}
          >
            {isSubmitting
              ? `Checking Positions ${positionsValidatedCount} / ${
                  spaceItem.numColumns * spaceItem.numRows
                }`
              : 'Generate Position List'}
          </Button>
        </form>
      </WidgetBody>
    </WidgetView>
  );
}
