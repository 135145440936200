import { useCallback, useEffect, useState } from 'react';

import { IconButton } from '@sb/ui/components';
import { BulletsIcon } from '@sb/ui/icons';
import WidgetButtonGroup from '@sbrc/components/visualizer-view-shared/widget-panel/WidgetButtonGroup';

import MoveRobotToolCoordinates from './MoveRobotToolCoordinates';
import { useMoveRobotViewContext } from './shared';

const MoveRobotViewPosePopover = () => {
  const { isCameraView, isControllingLiveRobot, isLiveRobotConnected } =
    useMoveRobotViewContext();

  const isControllingLiveRobotDisconnected =
    isControllingLiveRobot && !isLiveRobotConnected;

  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(true);

  const closePopover = useCallback(() => setIsPopoverOpen(false), []);

  const isLabelVisible = !isControllingLiveRobotDisconnected && !isPopoverOpen;

  useEffect(() => {
    if (isCameraView || isControllingLiveRobotDisconnected) {
      setIsPopoverOpen(false);
    }
  }, [isCameraView, isControllingLiveRobotDisconnected]);

  return (
    <>
      {isPopoverOpen && <MoveRobotToolCoordinates onClose={closePopover} />}

      <WidgetButtonGroup position="left">
        <IconButton
          variant="white"
          onClick={() => setIsPopoverOpen(!isPopoverOpen)}
          label={isLabelVisible ? 'Pose' : undefined}
          isActive={isPopoverOpen}
          disabled={isCameraView || isControllingLiveRobotDisconnected}
          data-testid="move-robot-view-pose-popover-trigger-button"
        >
          <BulletsIcon />
        </IconButton>
      </WidgetButtonGroup>
    </>
  );
};

export default MoveRobotViewPosePopover;
