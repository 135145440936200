import { useState } from 'react';

import type { Routine } from '@sb/types';
import { Dropdown, MenuItem } from '@sb/ui/components';
import { EditIcon, SettingsIcon, TrashIcon } from '@sb/ui/icons';

import type { WidgetState } from './types';

import styles from './SpaceItemSettings.module.css';

interface SpaceItemSettingsProps {
  spaceItem: Routine.SpaceItem;
  setWidgetState: (state: WidgetState) => void;
}

export default function SpaceItemSettings({
  spaceItem,
  setWidgetState,
}: SpaceItemSettingsProps) {
  const [isOpen, setIsOpen] = useState(false);

  const dropdownContent = (
    <div>
      <MenuItem
        startIcon={<EditIcon />}
        onClick={(e) => {
          e.stopPropagation();
          setWidgetState({ renameItem: spaceItem });
        }}
      >
        Rename
      </MenuItem>
      <MenuItem
        startIcon={<TrashIcon />}
        variant="alertPrimary"
        onClick={(e) => {
          e.stopPropagation();
          setWidgetState({ removeItemID: spaceItem.id });
        }}
      >
        Delete
      </MenuItem>
    </div>
  );

  return (
    <div
      className={styles.button}
      data-testid="space-widget-space-item-settings-button"
      onClick={(e) => {
        e.stopPropagation();
        setIsOpen(!isOpen);
      }}
      role="button"
    >
      <Dropdown
        content={dropdownContent}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <SettingsIcon
          className={styles.icon}
          size="small"
          role="button"
          aria-label="Settings"
        />
      </Dropdown>
    </div>
  );
}
