import { useEffect, useMemo } from 'react';

import type { ArmJointPositions } from '@sb/motion-planning';
import { Button, Typography } from '@sb/ui/components';
import {
  ArrowBackIcon,
  CompletedIcon,
  MoveArrowIcon,
  SaveIcon,
  WarningIcon,
} from '@sb/ui/icons';
import { margin } from '@sb/ui/styles';
import { useRobotJointAngles, useRoutineRunnerHandle } from '@sbrc/hooks';
import {
  areJointAnglesEqual,
  getRobotPosition,
  ROBOT_JOINT_ANGLES_DEFAULT,
} from '@sbrc/utils';

import WidgetBody from '../../widget-panel/WidgetBody';
import WidgetView from '../../widget-panel/WidgetView';
import SpaceItemIcon from '../SpaceItemIcon';

import type { EditSpaceItemProps } from './types';
import { useGetSyncButtonProps } from './useGetSyncButtonProps';

import styles from './EditSinglePosition.module.css';

export function EditSinglePosition({
  onClose,
  routineRunnerHandleArgs,
  spaceItem,
  widgetState,
  setWidgetState,
  setTargetJointAngles,
}: EditSpaceItemProps) {
  const routineRunnerHandle = useRoutineRunnerHandle(routineRunnerHandleArgs);
  const robotJointAngles = useRobotJointAngles(routineRunnerHandleArgs);

  const { positions } = spaceItem;

  const isUnsaved = positions.length === 0;

  const currentPositionJointAngles: ArmJointPositions =
    positions[0]?.jointAngles ?? robotJointAngles ?? ROBOT_JOINT_ANGLES_DEFAULT;

  const isRobotAtCurrentPosition = useMemo(() => {
    return areJointAnglesEqual(robotJointAngles, currentPositionJointAngles);
  }, [robotJointAngles, currentPositionJointAngles]);

  useEffect(() => {
    setTargetJointAngles(currentPositionJointAngles);
  }, [currentPositionJointAngles, setTargetJointAngles]);

  useEffect(() => {
    return () => setTargetJointAngles(null);
  }, [setTargetJointAngles]);

  const getSyncButtonProps = useGetSyncButtonProps(routineRunnerHandleArgs);

  const handleSavePosition = () => {
    const robotPosition = getRobotPosition(routineRunnerHandle);

    if (robotPosition) {
      const item = {
        ...spaceItem,
        positions: [robotPosition],
      };

      setWidgetState({
        ...widgetState,
        item,
        updateItem: item,
      });
    }
  };

  const isSaveButtonDisabled = !isUnsaved && isRobotAtCurrentPosition;

  const saveButtonLabel = positions.length === 0 ? 'Set' : 'Replace';

  return (
    <WidgetView
      onClose={onClose}
      headerTitle={spaceItem.name}
      headerIcon={
        <>
          <Button
            aria-label="Back"
            data-testid="space-widget-header-back-button"
            variant="gray"
            startIcon={<ArrowBackIcon />}
            size="extraSmall"
            onClick={() => setWidgetState({})}
          />
          <SpaceItemIcon itemKind={spaceItem.kind} size="small" />
        </>
      }
    >
      <WidgetBody>
        <Typography className={margin.bottom.medium} variant="medium">
          {positions.length === 0 ? (
            'Move'
          ) : (
            <>
              <strong>Position saved.</strong> To change, move
            </>
          )}{' '}
          the arm to the required position then return to this panel and tap the
          “{saveButtonLabel}” button.
        </Typography>

        <div className={styles.actions}>
          {positions.length === 0 ? (
            <WarningIcon className={styles.unsavedIcon} size="small" />
          ) : (
            <CompletedIcon className={styles.savedIcon} size="small" />
          )}

          <Button
            data-testid="space-widget-position-list-save-position-button"
            variant="secondary"
            disabled={isSaveButtonDisabled}
            onClick={handleSavePosition}
            startIcon={<SaveIcon />}
          >
            {saveButtonLabel}
          </Button>

          <Button
            data-testid="space-widget-position-list-load-position-button"
            variant="gray"
            startIcon={<MoveArrowIcon />}
            {...getSyncButtonProps(
              currentPositionJointAngles,
              isRobotAtCurrentPosition,
            )}
          >
            Go Here
          </Button>
        </div>
      </WidgetBody>
    </WidgetView>
  );
}
