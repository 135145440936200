import { useMemo } from 'react';

import type { KinematicState } from '@sb/routine-runner';
import { convertEulerPose } from '@sbrc/utils';

import type {
  ControlModeState,
  TargetJointAnglesState,
  TargetPoseState,
} from './shared';

export function useGhostRobotState(
  controlMode: ControlModeState,
  isControllingLiveRobot: boolean,
  targetJointAngles: TargetJointAnglesState,
  targetPose: TargetPoseState,
): Partial<KinematicState> | null {
  return useMemo(() => {
    if (controlMode === 'jointControlDual' || controlMode === 'space') {
      const jointAngles = isControllingLiveRobot
        ? targetJointAngles.liveRobot
        : targetJointAngles.vizbot;

      if (jointAngles) {
        return { jointAngles };
      }
    }

    if (controlMode === 'toolControlTarget') {
      const pose = isControllingLiveRobot
        ? targetPose.liveRobot
        : targetPose.vizbot;

      if (pose) {
        return {
          tooltipPoint: convertEulerPose.toCartesian(pose),
        };
      }
    }

    return null;
  }, [controlMode, isControllingLiveRobot, targetJointAngles, targetPose]);
}
