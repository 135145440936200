import type { Routine } from '@sb/types';

export function getSpaceItemKindName(itemKind: Routine.SpaceItem['kind']) {
  switch (itemKind) {
    case 'singlePosition':
      return 'Single Position';
    case 'gridPositionList':
      return 'Grid Position List';
    case 'freeformPositionList':
      return 'Freeform Position List';
    case 'environmentObject':
      return 'Object';
    default: {
      const exhaustive: never = itemKind; // ensure type exhaustive

      return `${exhaustive} Item`;
    }
  }
}
