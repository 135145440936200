import { Button } from '@sb/ui/components';
import { VisualizerIcon } from '@sb/ui/icons';
import WarningWidget from '@sbrc/components/visualizer-view-shared/warning-widget/WarningWidget';
import WidgetText from '@sbrc/components/visualizer-view-shared/widget-panel/WidgetText';

import { useMoveRobotViewContext } from '../shared';

const NonIdleModeDisconnected = () => {
  const { robot, setIsControllingLiveRobot } = useMoveRobotViewContext();

  const onSwitchToControllingVisualizerChange = () => {
    setIsControllingLiveRobot(false);
  };

  return (
    <WarningWidget>
      <WidgetText>
        <strong>Robot is Disconnected.</strong> It appears that {robot.name} has
        lost connection. Please try reconnecting or switch to Controlling
        Visualizer.
      </WidgetText>

      <Button
        startIcon={<VisualizerIcon />}
        isFullWidth
        onClick={onSwitchToControllingVisualizerChange}
        variant="primary"
      >
        Switch to Controlling Visualizer
      </Button>
    </WarningWidget>
  );
};

export default NonIdleModeDisconnected;
