import cx from 'classnames';
import { useState } from 'react';

import type { CartesianPose } from '@sb/geometry';
import type { MotionKind } from '@sb/motion-planning';
import { Button, Checkbox, InputLabel, NumberInput } from '@sb/ui/components';
import { margin, padding } from '@sb/ui/styles';
import { DistanceNumberInput } from '@sbrc/components/units';
import {
  useFeatureFlag,
  useGuidedMode,
  useRobotTooltipState,
  useToast,
} from '@sbrc/hooks';
import { convertEulerPose } from '@sbrc/utils';
import type { EulerPose } from '@sbrc/utils';

import getAdHocSpeedProfile from '../../../visualizer-view-shared/getAdHocSpeedProfile';
import { useMoveRobotViewContext } from '../../shared';

import useTargetEulerPose from './useTargetEulerPose';

import styles from './ToolControlTargetMode.module.css';

const POSE_ROTATION_DECIMAL_PLACE = 4;

const TOOL_POSE_LABEL_LIST: Array<{
  prop: keyof EulerPose;
  label: string;
  color: 'X' | 'Y' | 'Z';
  kind: 'distance' | 'angle';
}> = [
  {
    prop: 'X',
    label: 'X',
    color: 'X',
    kind: 'distance',
  },
  {
    prop: 'Y',
    label: 'Y',
    color: 'Y',
    kind: 'distance',
  },
  {
    prop: 'Z',
    label: 'Z',
    color: 'Z',
    kind: 'distance',
  },
  {
    prop: 'Rx',
    label: 'Roll',
    color: 'X',
    kind: 'angle',
  },
  {
    prop: 'Ry',
    label: 'Pitch',
    color: 'Y',
    kind: 'angle',
  },
  {
    prop: 'Rz',
    label: 'Yaw',
    color: 'Z',
    kind: 'angle',
  },
];

const ToolControlTargetMode = () => {
  const { controlViewRoutineRunnerHandle, robot, isControllingLiveRobot } =
    useMoveRobotViewContext();

  const isAdHocFullSpeed = useFeatureFlag('adHocFullSpeed');

  const { setToast } = useToast();

  const { targetPose, setTargetEulerPose, resetAllTargets } =
    useTargetEulerPose();

  const [motionKind, setMotionKind] = useState<MotionKind>('joint');

  const hasTargets = Boolean(targetPose);

  const routineRunnerArgs = {
    robotID: robot.id,
    isVizbot: !isControllingLiveRobot,
  };

  // Cartesian
  const tooltip: CartesianPose | null = useRobotTooltipState(routineRunnerArgs);

  // Euler
  const tooltipPose: EulerPose =
    convertEulerPose.fromNullableCartesian(tooltip);

  const { runAdHocCommand, stopGuidedMode } = useGuidedMode(routineRunnerArgs);

  const onHoldApplyChanges = () => {
    if (targetPose) {
      const targetCartesianPose = convertEulerPose.toCartesian(targetPose);

      const moveRobotToTargetPosition = async () => {
        const speedProfile = await getAdHocSpeedProfile(
          robot.id,
          isControllingLiveRobot,
          isAdHocFullSpeed,
        );

        return controlViewRoutineRunnerHandle.moveToCartesianSpacePose(
          targetCartesianPose,
          motionKind,
          speedProfile,
        );
      };

      runAdHocCommand({
        onRunCommand: moveRobotToTargetPosition,
        onError(error) {
          setToast({
            kind: 'error',
            message: `Error setting pose target: ${error}`,
          });
        },
        onSuccess() {
          resetAllTargets();
        },
      });
    }
  };

  return (
    <>
      {TOOL_POSE_LABEL_LIST.map((position) => {
        const { label, color, prop, kind } = position;

        const value = targetPose ? targetPose[prop] : tooltipPose[prop];

        return (
          <div
            key={`toolControl-${label}`}
            className={cx(margin.small, styles.input)}
          >
            <InputLabel
              className={cx(
                margin.right.extraSmall,
                styles.inputLabel,
                styles[`color${color}`],
              )}
              labelPosition="left"
            >
              {label}
            </InputLabel>
            {kind === 'distance' ? (
              <DistanceNumberInput
                className={styles.inputField}
                value={value}
                onChange={(num) => setTargetEulerPose(prop, num)}
              />
            ) : (
              <NumberInput
                className={styles.inputField}
                suffix="deg"
                value={value}
                decimalPlaces={POSE_ROTATION_DECIMAL_PLACE}
                onChange={(num) => setTargetEulerPose(prop, num)}
              />
            )}
          </div>
        );
      })}

      <Checkbox
        className={cx(margin.small, margin.top.medium)}
        checked={motionKind === 'line'}
        onChange={(e) => setMotionKind(e.target.checked ? 'line' : 'joint')}
      >
        Move in a straight line
      </Checkbox>

      <div
        className={cx(styles.bottomContainer, margin.top.small, padding.small)}
      >
        <Button
          variant="blackPrimary"
          isFullWidth
          onHold={onHoldApplyChanges}
          onRelease={stopGuidedMode}
          disabled={!hasTargets}
          data-testid="move-robot-control-panel-widgets-tool-control-button"
        >
          Hold to Apply Changes
        </Button>
      </div>
    </>
  );
};

export default ToolControlTargetMode;
