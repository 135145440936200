import { useCallback, useMemo, useState } from 'react';

import type { CartesianPose } from '@sb/geometry';
import { Loader } from '@sb/ui/components';
import { BulletsIcon, MoreIcon } from '@sb/ui/icons';
import { convertDegreesToRadians } from '@sb/utilities';
import WidgetPanel from '@sbrc/components/visualizer-view-shared/widget-panel/WidgetPanel';
import WidgetView from '@sbrc/components/visualizer-view-shared/widget-panel/WidgetView';
import { useDistanceUnitInfo, useRobotTooltipState } from '@sbrc/hooks';
import type { EulerPose } from '@sbrc/utils';
import { convertEulerPose } from '@sbrc/utils';

import { JOINT_ANGLE_PRECISION, useMoveRobotViewContext } from './shared';
import ToolCoordinate from './ToolCoordinate';
import ToolCoordinateRotationTypeModal from './ToolCoordinateRotationTypeModal';
import type { RotationState, RotationUnit } from './types';

import styles from './MoveRobotToolCoordinates.module.css';

interface MoveRobotToolCoordinatesProps {
  onClose: () => void;
}

interface ToolCoordinateLabel {
  color: 'X' | 'Y' | 'Z';
  label: string;
}

const TOOL_COORDINATE_LABEL_LIST: Record<string, ToolCoordinateLabel> = {
  X: { label: 'X', color: 'X' },
  Rx: { label: 'Roll', color: 'X' },
  Y: { label: 'Y', color: 'Y' },
  Ry: { label: 'Pitch', color: 'Y' },
  Z: { label: 'Z', color: 'Z' },
  Rz: { label: 'Yaw', color: 'Z' },
};

interface UnitData {
  unitLabel: string;
  convertValue: (v: number) => number;
  decimalPlaces: number;
}

const UNIT_DATA: Record<RotationUnit, UnitData> = {
  degrees: {
    unitLabel: 'deg',
    convertValue: (v) => v,
    decimalPlaces: JOINT_ANGLE_PRECISION,
  },
  radians: {
    unitLabel: 'rad',
    convertValue: convertDegreesToRadians,
    decimalPlaces: 3,
  },
};

const POSITION_LABEL_LIST = ['X', 'Y', 'Z'];

const EULER_ANGLE_LABEL_LIST = ['Rx', 'Ry', 'Rz'];

const QUATERNION_LABEL_LIST = ['i', 'j', 'k', 'w'];

const QUATERION_LABEL_DECIMAL_PLACES = 3;

const MoveRobotToolCoordinates = ({
  onClose,
}: MoveRobotToolCoordinatesProps) => {
  const distanceUnitInfo = useDistanceUnitInfo();

  const { isControllingLiveRobot, robot } = useMoveRobotViewContext();

  const [rotation, setRotation] = useState<RotationState>('euler');

  const [rotationUnit, setRotationUnit] = useState<RotationUnit>('degrees');

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const openModal = useCallback(() => setIsModalOpen(true), []);

  const closeModal = useCallback(() => setIsModalOpen(false), []);

  const routineRunnerArgs = {
    robotID: robot.id,
    isVizbot: !isControllingLiveRobot,
  };

  const tooltipPoint = useRobotTooltipState(routineRunnerArgs);

  /**
   * Routine-runner gives us tool coordinates (pose) as
   * a cartesian point. However, the frontend displays them
   * as Euler.
   */
  const eulerAngles = useMemo(() => {
    return convertEulerPose.fromNullableCartesian(tooltipPoint);
  }, [tooltipPoint]);

  const headerButton = (
    <button
      className={styles.headerButton}
      onClick={openModal}
      data-testid="move-robot-tool-coordinates-more-trigger-button"
    >
      <MoreIcon className={styles.moreIcon} />
    </button>
  );

  return (
    <>
      <WidgetPanel position="bottom-left">
        <WidgetView
          onClose={onClose}
          headerIcon={<BulletsIcon />}
          headerTitle="Tooltip Pose"
          headerButton={headerButton}
        >
          <div className={styles.moveRobotToolCoordinates}>
            {tooltipPoint === undefined && (
              <div className={styles.loader}>
                <Loader />
              </div>
            )}

            <div>
              {tooltipPoint &&
                POSITION_LABEL_LIST.map((position) => {
                  const { label, color } = TOOL_COORDINATE_LABEL_LIST[position];

                  const value = eulerAngles[position as keyof EulerPose];

                  return (
                    <ToolCoordinate
                      key={position}
                      coordinateLabel={label}
                      color={color}
                      decimalPlaces={distanceUnitInfo.decimalPlaces}
                      unitLabel={distanceUnitInfo.abbreviation}
                      value={distanceUnitInfo.fromMeters(value)}
                    />
                  );
                })}
            </div>

            <div>
              {tooltipPoint &&
                (rotation === 'euler' ? (
                  EULER_ANGLE_LABEL_LIST.map((euler) => {
                    const { label, color } = TOOL_COORDINATE_LABEL_LIST[euler];

                    const value = eulerAngles[euler as keyof EulerPose];

                    const { convertValue, unitLabel, decimalPlaces } =
                      UNIT_DATA[rotationUnit];

                    return (
                      <ToolCoordinate
                        key={euler}
                        coordinateLabel={label}
                        color={color}
                        decimalPlaces={decimalPlaces}
                        unitLabel={unitLabel}
                        value={convertValue(value)}
                      />
                    );
                  })
                ) : (
                  <>
                    {QUATERNION_LABEL_LIST.map((quaternion) => {
                      return (
                        <ToolCoordinate
                          key={quaternion}
                          coordinateLabel={quaternion}
                          decimalPlaces={QUATERION_LABEL_DECIMAL_PLACES}
                          value={
                            tooltipPoint[quaternion as keyof CartesianPose]
                          }
                        />
                      );
                    })}
                  </>
                ))}
            </div>
          </div>
        </WidgetView>
      </WidgetPanel>

      <ToolCoordinateRotationTypeModal
        rotation={rotation}
        rotationUnit={rotationUnit}
        isOpen={isModalOpen}
        onClose={closeModal}
        onRotationChange={setRotation}
        onUnitChange={setRotationUnit}
      />
    </>
  );
};

export default MoveRobotToolCoordinates;
