import type { ButtonProps } from '@sb/ui/components';
import { Button, Typography } from '@sb/ui/components';
import {
  CompletedIcon,
  MoveArrowIcon,
  SaveIcon,
  TrashIcon,
  WarningIcon,
} from '@sb/ui/icons';

import styles from './EditGridPositionList.module.css';

interface EditGridCornerProps {
  label: string;
  isUnsaved: boolean;
  areThreeCornersSaved: boolean;
  isRobotAtAnyCorner: boolean;
  isSubmitting: boolean;
  syncButtonProps: ButtonProps | null;
  handleClearCorner: () => void;
  handleSaveCorner: () => void;
}

export function EditGridCorner({
  label,
  isUnsaved,
  areThreeCornersSaved,
  isRobotAtAnyCorner,
  isSubmitting,
  syncButtonProps,
  handleClearCorner,
  handleSaveCorner,
}: EditGridCornerProps) {
  const isInferred = isUnsaved && areThreeCornersSaved;

  const getIcon = () => {
    if (isInferred) {
      return (
        <CompletedIcon size="small" className={styles.inferredCornerIcon} />
      );
    }

    if (isUnsaved) {
      return <WarningIcon size="small" className={styles.unsavedCornerIcon} />;
    }

    return <CompletedIcon size="small" className={styles.savedCornerIcon} />;
  };

  const getPrimaryAction = () => {
    if (isInferred) {
      return <Typography color="gray">(Inferred)</Typography>;
    }

    if (areThreeCornersSaved && !isUnsaved) {
      return (
        <Button
          type="button"
          startIcon={<TrashIcon />}
          variant="alertSecondary"
          size="small"
          onClick={() => handleClearCorner()}
          disabled={isSubmitting}
        >
          Clear
        </Button>
      );
    }

    return (
      <Button
        type="button"
        startIcon={<SaveIcon />}
        variant="secondary"
        size="small"
        onClick={() => handleSaveCorner()}
        disabled={isRobotAtAnyCorner || isSubmitting || isInferred}
      >
        {isUnsaved ? 'Set' : 'Replace'}
      </Button>
    );
  };

  return (
    <div className={styles.cornerRow}>
      {getIcon()}

      <Typography variant="small" isBold className={styles.cornerLabel}>
        {label}
      </Typography>

      {getPrimaryAction()}

      <Button
        type="button"
        startIcon={<MoveArrowIcon />}
        variant="gray"
        size="small"
        {...syncButtonProps}
      >
        Go Here
      </Button>
    </div>
  );
}
