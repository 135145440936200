import type { ArmJointPositions } from '@sb/motion-planning';
import type { ButtonProps } from '@sb/ui/components';
import {
  useFeatureFlag,
  useGuidedMode,
  useIsAnotherSessionRunningAdHocCommand,
  useRoutineRunnerHandle,
} from '@sbrc/hooks';
import type { UseRoutineRunnerHandleArguments } from '@sbrc/hooks';
import { VizbotRoutineRunnerHandle } from '@sbrc/services';

import getAdHocSpeedProfile from '../../getAdHocSpeedProfile';

export function useGetSyncButtonProps(
  routineRunnerHandleArgs: UseRoutineRunnerHandleArguments,
) {
  const routineRunnerHandle = useRoutineRunnerHandle(routineRunnerHandleArgs);

  const isAdHocFullSpeed = useFeatureFlag('adHocFullSpeed');

  const isAnotherSessionRunningAdHocCommand =
    useIsAnotherSessionRunningAdHocCommand(routineRunnerHandleArgs);

  const { runAdHocCommand, stopGuidedMode } = useGuidedMode(
    routineRunnerHandleArgs,
  );

  return (
    jointAngles: ArmJointPositions | undefined,
    isDisabled: boolean | undefined,
  ): ButtonProps | null => {
    if (!jointAngles) {
      return { disabled: isDisabled };
    }

    const disabled = isDisabled || isAnotherSessionRunningAdHocCommand;

    if (routineRunnerHandle instanceof VizbotRoutineRunnerHandle) {
      return {
        disabled,
        onClick: () => {
          routineRunnerHandle.setJointPositions(jointAngles);
        },
      };
    }

    const { robotID, isVizbot } = routineRunnerHandleArgs;

    if (robotID && !isVizbot) {
      return {
        disabled,
        onHold: () => {
          const moveRobotToTargetPosition = async () => {
            const speedProfile = await getAdHocSpeedProfile(
              robotID,
              true,
              isAdHocFullSpeed,
            );

            return routineRunnerHandle.moveToJointSpacePoint(
              jointAngles,
              speedProfile,
            );
          };

          runAdHocCommand({ onRunCommand: moveRobotToTargetPosition });
        },
        onRelease: stopGuidedMode,
      };
    }

    return null;
  };
}
