import { v4 as uuid } from 'uuid';

import { ZERO_POSE } from '@sb/geometry';
import type { Routine } from '@sb/types';

import { UNSAVED_POSITION } from './types';

export type SpaceItemKind = Routine.SpaceItem['kind'];

export function generateNewSpaceItem(
  kind: SpaceItemKind,
  name: string,
  description: string,
): Routine.SpaceItem {
  const commonProps = {
    id: uuid(),
    name,
    description,
    positions: [],
  };

  switch (kind) {
    case 'gridPositionList':
      return {
        ...commonProps,
        kind,
        cornerA: null,
        cornerB: null,
        cornerC: null,
        cornerD: null,
        numColumns: 3,
        numRows: 3,
      };

    case 'freeformPositionList':
      return {
        ...commonProps,
        kind,
        positions: [UNSAVED_POSITION],
      };

    case 'singlePosition':
      return {
        ...commonProps,
        kind,
      };

    case 'environmentObject':
      return {
        ...commonProps,
        kind,
        fileURL: null,
        fileName: null,
        pose: ZERO_POSE,
      };

    default: {
      const exhaustive: never = kind; // ensure type exhaustive
      throw new Error(`Space item kind ${exhaustive} not supported`);
    }
  }
}
