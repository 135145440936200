import Image from 'next/image';

import WarningWidget from '@sbrc/components/visualizer-view-shared/warning-widget/WarningWidget';
import WidgetText from '@sbrc/components/visualizer-view-shared/widget-panel/WidgetText';
import { STANDARD_BOTS_ANTIGRAVITY_SRC } from '@sbrc/utils/constants';

import styles from './AntigravityMode.module.css';

const AntigravityMode = () => {
  return (
    <WarningWidget>
      <WidgetText className={styles.text}>
        <strong>Robot is in Antigravity Mode.</strong> To move the robot, please
        hold the Antigravity button located on the robot's wrist and drag the
        arm to the desired position. Releasing the button will stop antigravity
        mode.
      </WidgetText>

      <Image
        src={STANDARD_BOTS_ANTIGRAVITY_SRC}
        width={290}
        height={110}
        alt="Antigravity robot icon"
        className={styles.image}
      />
    </WarningWidget>
  );
};

export default AntigravityMode;
