import { useEffect } from 'react';

import {
  IOIcon,
  JointsIcon,
  MoveIcon,
  SettingsIcon,
  SpaceIcon,
} from '@sb/ui/icons';
import { useFeatureFlag } from '@sbrc/hooks';

import { EquipmentWidgetIconButtons } from '../visualizer-view-shared/widget-panel/EquipmentWidgetIconButtons';
import WidgetButtonGroup from '../visualizer-view-shared/widget-panel/WidgetButtonGroup';
import { WidgetIconButton } from '../visualizer-view-shared/widget-panel/WidgetIconButton';

import MoveRobotCamera from './MoveRobotCamera';
import {
  useControlModeButtonGroupStore,
  useMoveRobotViewContext,
} from './shared';

const ControlModeButtonGroup = () => {
  const {
    controlMode,
    nonIdleMode,
    isControllingLiveRobot,
    setControlMode,
    routine,
  } = useMoveRobotViewContext();

  const enableCamera = useFeatureFlag('camera');

  const {
    isJointControlDualValueMode,
    isToolControlTargetMode,
    onChangeControlMode,
  } = useControlModeButtonGroupStore();

  useEffect(() => {
    onChangeControlMode(controlMode);
  }, [onChangeControlMode, controlMode]);

  const isRoutineLoaded = !!routine;

  const disabled = Boolean(nonIdleMode);

  const commonIconButtonProps = {
    activeWidget: controlMode,
    setActiveWidget: setControlMode,
    disabled,
  };

  return (
    <WidgetButtonGroup>
      {enableCamera && isControllingLiveRobot && <MoveRobotCamera />}

      <WidgetIconButton
        {...commonIconButtonProps}
        forWidget={
          isToolControlTargetMode ? 'toolControlTarget' : 'toolControl'
        }
        label="Tooltip"
        icon={<MoveIcon />}
        tooltip="Control the position and orientation of the arm"
        data-testid="move-robot-controls-tools-button"
      />

      <WidgetIconButton
        {...commonIconButtonProps}
        forWidget={
          isJointControlDualValueMode
            ? 'jointControlDual'
            : 'jointControlSingle'
        }
        label="Joints"
        icon={<JointsIcon />}
        tooltip="Control individual joint angles"
        data-testid="move-robot-controls-joints-button"
      />

      <EquipmentWidgetIconButtons {...commonIconButtonProps} />

      <WidgetIconButton
        {...commonIconButtonProps}
        forWidget="space"
        label="Space"
        icon={<SpaceIcon />}
        tooltip="Configure positions around the robot's working space to use in routines"
        disabled={disabled || !isRoutineLoaded}
        data-testid="move-robot-controls-space-button"
      />

      <WidgetIconButton
        {...commonIconButtonProps}
        forWidget="io"
        label="I/O"
        icon={<IOIcon />}
        tooltip="View I/O interface tooling"
        disabled={disabled || !isControllingLiveRobot}
        data-testid="move-robot-controls-io-button"
      />

      <WidgetIconButton
        {...commonIconButtonProps}
        forWidget="settings"
        label="Settings"
        icon={<SettingsIcon />}
        tooltip="Control robot settings"
        data-testid="move-robot-controls-settings-button"
      />
    </WidgetButtonGroup>
  );
};

export default ControlModeButtonGroup;
